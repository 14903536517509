// @flow
import * as React from 'react';
import classNames from 'classnames';

import { Text } from '../Text';
import * as css from './Button.scss';

type Props = {
	className?: string,
	label: string,
	color?: 'green' | 'blue',
	small?: boolean,
	disabled?: boolean,
};

class Button extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
		color: 'green',
		small: false,
		disabled: false,
	};

	render() {
		const { className, label, color, small, ...rest } = this.props;

		return (
			<button
				{...rest}
				className={classNames(css.button, css[color], small && css.small, className)}
				type="button"
			>
				<Text className={css.label} type="L1" weight="bold">
					{label}
				</Text>
			</button>
		);
	}
}

export { Button };
