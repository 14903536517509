// @flow
import config from '../../../config';

export const appConfig = config;
export const ORIGINAL_IMAGE_IDENTIFIER = '_xoriginal';

export const APP_CONTAINER_CLASS = 'root';
export const APP_CONTAINER_SELECTOR = `.${APP_CONTAINER_CLASS}`;
export const CAN_USE_DOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
export const IS_DEVEL = process.env.NODE_ENV !== 'production';
