import React from 'react';
import { componentDidMount } from 'common/utils/hooks';

const withParentWindowAutoHeight = Component => {
	function WrappedComponent(props) {
		const containerRef = React.useRef(null);

		const observer = React.useCallback(() => {
			requestAnimationFrame(observer);
			const height = containerRef.current.offsetHeight;
			const parentHeight = window.innerHeight;
			const isDiff = height !== parentHeight && Math.abs(height - parentHeight) > 3;
			const isFrame = window !== window.parent;

			if (!isDiff) {
				return;
			}

			if (isFrame) {
				window.parent.postMessage(
					{
						// eslint-disable-next-line
						payload: { iframeHeight: height + '' },
						source: 'mako-charts',
						type: 'resize',
						sender: 'MAKO',
					},
					'*'
				);
			} else {
				document.documentElement.style.height = `${height}px`;
				document.body.style.height = `${height}px`;

				document.documentElement.style.overflow = 'auto';
				document.body.style.overflow = 'auto';
			}
		}, [containerRef]);

		componentDidMount(() => {
			requestAnimationFrame(observer);
			return () => cancelAnimationFrame(observer);
		});

		return (
			<div ref={containerRef}>
				<Component {...props} />
			</div>
		);
	}

	WrappedComponent.displayName = `withParentWindowAutoHeight(${Component.name})`;

	return WrappedComponent;
};

export default withParentWindowAutoHeight;
