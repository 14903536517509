// @flow
import Api from './ApiWorker';

export const createItem = <I: AbstractItem>(params: CreateItemParams<I>): Promise<BackEndResponse<I>> =>
	Api.send<I>({
		url: '/crud/create',
		method: 'POST',
		body: params,
		secure: true,
	});

export const getItem = <I>(params: GetItemParams): Promise<BackEndResponse<I>> =>
	Api.send<I>({
		url: '/crud/get',
		method: 'POST',
		body: params,
		secure: true,
	});

export const getItems = <I, F>(params: GetItemsParams<F>): Promise<BackEndResponse<GetItemsResult<I>>> =>
	Api.send<GetItemsResult<I>>({
		url: '/crud/get-items',
		method: 'POST',
		body: params,
		secure: true,
	});

export const updateItem = <I: AbstractItem>(params: UpdateItemParams<I>): Promise<BackEndResponse<I>> =>
	Api.send<I>({
		url: '/crud/update',
		method: 'POST',
		body: params,
		secure: true,
	});

export const deleteItem = (params: DeleteItemParams): Promise<BackEndResponse<DeleteItemParams>> =>
	Api.send<DeleteItemParams>({
		url: '/crud/delete',
		method: 'POST',
		body: params,
		secure: true,
	});

export const getChartItem = <I>(params: GetItemParams): Promise<BackEndResponse<I>> =>
	Api.send<I>({
		url: '/crud/get/chart',
		method: 'POST',
		body: params,
		secure: true,
	});

export const getGroupItem = <I>(params: GetItemParams): Promise<BackEndResponse<I>> =>
	Api.send<I>({
		url: '/crud/get/group',
		method: 'POST',
		body: params,
		secure: false,
	});

export const validateItemField = (params: ValidateItemFieldParams): Promise<BackEndResponse<DeleteItemParams>> =>
	Api.send<ValidateItemFieldParams>({
		url: '/crud/validate-item-field',
		method: 'POST',
		body: params,
		secure: true,
	});

// const response = await getItem<QuestionItem>({ collection: 'collection', id: 'id' });
// 	console.info(response.data);
