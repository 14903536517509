// @flow

export const getProviderName = (firebaseProviderID: string): 'facebook' | 'google' | 'anonymous' => {
	if (firebaseProviderID === 'facebook.com') {
		return 'facebook';
	}
	if (firebaseProviderID === 'google.com') {
		return 'google';
	}

	return 'anonymous';
};
