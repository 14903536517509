// @flow
import _ from 'lodash';

/*
 Here are only settings
 Data is gonna be uploaded from XLS > parsed to JSON > stored to Storage > used in Admin|Client
 */

const orig = 'https://charts-cdn.mako.co.il';
export const TEMPLATE_FILE = {
	bar: {
		json: `${orig}/public%2Ftemplate_bar.json?alt=media`,
		xlsx: `${orig}/public%2Ftemplate_bar.xlsx?alt=media`,
	},
	line: {
		json: `${orig}/public%2Ftemplate_line.json?alt=media`,
		xlsx: `${orig}/public%2Ftemplate_line.xlsx?alt=media`,
	},
};
export const CHART_TYPE = {
	bar: 'bar',
	line: 'line',
};
export const CHART_BAR_TYPE = {
	horizontal: 'horizontal',
	vertical: 'vertical',
};
export const CHART_X_TYPE = {
	name: 'name',
	date: 'date',
};
export const CHART_DATE_TYPE = {
	allDates: 'allDates',
	betweenDates: 'betweenDates',
	lastWeek: 'lastWeek',
	lastMonth: 'lastMonth',
	fiveDaysLastMonth: 'fiveDaysLastMonth',
	monthlyAvg: 'monthlyAvg',
};
export const CHART_FONT = {
	yonit: 'yonit',
	reforma: 'reforma',
};
export const CHART_BG = {
	mako: 'mako',
	n12: 'n12',
	default: 'default',
};
export const CHART_SOURCE = {
	file: 'file',
	api: 'api',
};
export const CHART_COLOR_TYPE = {
	single: 'single',
	multiple: 'multiple',
};

export const CHART_ORDER_BARS = {
	default: 'אוטומטי',
	highToLow: 'גבוה לנמוך',
	lowToHigh: 'נמוך לגבוה',
};

const barChart = {
	// id: '001', //                string uniq
	// name: 'Graph 001', //        string uniq
	filename: 'template_bar.xlsx',
	source: CHART_SOURCE.file,
	type: CHART_TYPE.bar,

	barType: CHART_BAR_TYPE.vertical,
	yMin: 0, //                  number
	yMax: 10000, //              number
	step: undefined, //               number
	xType: CHART_X_TYPE.name,

	// ONLY xType = date
	xDate: CHART_DATE_TYPE.allDates,
	xDateRange: '', // '05/07/2020-01/10/2020' enabled ONLY if xDate === between dates
	// ONLY xType = date END

	itemsMax: 2, //              number 2-10
	itemsName: [], //            ['Haifa', 'Jerusalem']
	crossLine: {
		enabled: false,
		title: '',
		color: '#E51728',
		position: undefined, //       number at y axis
	},
	btnAdd: {
		enabled: false,
		title: 'הוסף',
	},
	theme: {
		color: CHART_COLOR_TYPE.single, //        auto | certain color
		colorMain: '#365591',
		font: CHART_FONT.yonit,
		bg: CHART_BG.default,
	},
	texts: {
		title: 'כותרת ראשית',
		subtitle: 'כותרת משנה',
		// disclaimer: 'דיסקליימר [לינק].',
		disclaimerLink: 'דיסקליימר לינק',
		disclaimer: '',
		disclaimerLogoLink: 'disclaimerLogoLink',
		disclaimerLogoEnabled: false,
		disclaimerLogo: {},
	},
	barsOrder: CHART_ORDER_BARS.default,
	isFirstUploadedFile: false,
	isFirstSaved: false,
	isPercent: false,
	isActiveTabFromLeft: false,
};

const lineChart = {
	..._.omit(barChart, ['barType']),
	type: CHART_TYPE.line,
	xType: CHART_X_TYPE.date,
	itemsMax: 1, // number 1-10
	filename: 'template_line.xlsx',
};

export { lineChart, barChart };
