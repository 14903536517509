// @flow
import firebase from 'firebase/app';
import ApiWorker from 'api/ApiWorker';
import * as api from 'api';
import { CAN_USE_DOM } from 'common/constants';
import * as actions from 'client/constants/actions';

export const toggleSound = () => {
	return async (dispatch: Dispatch, getState: () => ClientStore) => {
		const state = getState();
		const nextValue = !state.settings.isSoundOn;

		if (CAN_USE_DOM) {
			const lsData = JSON.parse(window.localStorage.getItem('sound') || '{}');

			lsData[state.user.uid] = nextValue;
			window.localStorage.setItem('sound', JSON.stringify(lsData));
		}

		dispatch({
			type: actions.TOGGLE_SOUND,
			payload: nextValue,
		});
	};
};

export function getItem(id: string) {
	return async (dispatch: Dispatch) => {
		try {
			dispatch({ type: 'GET_SETTINGS.START' });

			const user = firebase.auth().currentUser;
			ApiWorker.token = await user.getIdToken(true);
			const settings = await api.getChartItem({ id, collection: 'charts' });

			if (settings.success && settings.data) {
				dispatch({
					type: 'GET_SETTINGS.SUCCESS',
					payload: settings.data,
				});

				return settings.data;
			}

			return null;
		} catch (e) {
			const msg: string = e.message;

			dispatch({ type: 'GET_SETTINGS.FAIL', payload: msg });
		}

		return null;
	};
}
