// @flow
import { COLLECTIONS } from 'client/constants';
import * as actions from 'client/constants/actions';
import * as api from 'api';

import { apiRequestAction } from './helpers/api-request';

export function getGroupItem(params: GetItemParams): any {
	return async (dispatch: Dispatch) => {
		const actionsMap = {
			[COLLECTIONS.GROUPS]: actions.GET_GROUPS_ITEM,
		};

		const requestAction = apiRequestAction({
			action: actionsMap[params.collection],
			request: api.getGroupItem,
			params,
		});

		return dispatch(requestAction);
	};
}
