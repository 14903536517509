// @flow
import produce from 'immer';
import _ from 'lodash';

import { CAN_USE_DOM } from 'common/constants';
import * as actions from 'client/constants/actions';

const initialState: ClientSettings = {
	texts: {},
	isSoundOn: true,
};

type Action =
	| { type: typeof actions.GET_SETTINGS.SUCCESS, payload: ClientSettings }
	| { type: typeof actions.LOGIN.SUCCESS, payload: User }
	| { type: typeof actions.CHECK_AUTH_STATE.SUCCESS, payload: User }
	| { type: typeof actions.TOGGLE_SOUND, payload: boolean };

export default function settingsReducer(state: ClientSettings = initialState, action: Action) {
	return produce<any>(state, (draft: ClientSettings) => {
		switch (action.type) {
			case actions.GET_SETTINGS.SUCCESS:
				return action.payload;

			case actions.LOGIN.SUCCESS:
			case actions.CHECK_AUTH_STATE.SUCCESS:
				if (CAN_USE_DOM) {
					const lsData = JSON.parse(window.localStorage.getItem('sound') || '{}');
					draft.isSoundOn = _.get(lsData, action.payload.uid, true);
				}
				break;

			case actions.TOGGLE_SOUND:
				draft.isSoundOn = action.payload;
				break;

			default:
				return state;
		}
	});
}
