import ReactGA from 'react-ga';
import { IS_DEVEL } from 'client/constants';
import debug from 'debug';

const log = debug('app:client:analytics');
let options = {};

if (IS_DEVEL) {
	options = { debug: false };
}

export const tracker = {
	init: function init(id) {
		if (typeof window === 'undefined') {
			return;
		}
		if (!id) {
			console.warn('There is no google analytics ID.');
			return;
		}
		log('init', id);
		ReactGA.initialize(id, options);
	},
	pageview: function pageview(...args) {
		log('pageview', ...args);
		ReactGA.pageview(...args);
	},
	event: function event(...args) {
		log('event', ...args);
		ReactGA.event(...args);
	},
	ga: function ga(args) {
		log('ga', ...args);
		ReactGA.ga(...args);
	},
};

export function track({ type, payload }) {
	try {
		tracker[type](payload);
	} catch (error) {
		if (IS_DEVEL) {
			console.warn('Failed to track analytics event', error);
		}
	}
}

export const events = {
	clickOnTab: (title, tabName) => {
		track({
			type: 'event',
			payload: { category: `${title}`, action: 'Tab_', label: tabName },
		});
	},
	// clickOnCheckbox: (title, btnName, checkboxName) => {
	// 	track({
	// 		type: 'event',
	// 		payload: { category: `${title}`, action: `הקלקה בתוך ${btnName}`, label: `${checkboxName}` },
	// 	});
	// },
	clickOnCityCheckbox: (title, name) => {
		track({
			type: 'event',
			payload: {
				category: `${title}`,
				action: `selections`,
				label: name,
			},
		});
	},
	clickOnAddBtn: (title, btnName) => {
		track({
			type: 'event',
			payload: { category: `${title}`, action: `Add button_${btnName}` },
		});
	},
	pageView: url =>
		track({
			type: 'ga',
			payload: ['send', 'pageview', `/${url}`],
		}),
};
