// @flow
import { combineReducers } from 'redux';
import type { Reducer, Action } from 'redux';

import user from './user';
import settings from './settings';
import groups from './groups';

const reducer: Reducer<ClientStore, Action<{ type: string, payload: any }>> = combineReducers({
	user,
	settings,
	groups,
});

export default reducer;
