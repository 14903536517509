/* eslint-disable no-case-declarations prefer-destructuring */
import { CHART_DATE_TYPE, CHART_X_TYPE } from 'common/constants/chart-model';
import _ from 'lodash';

export function parseBarData(names, chartTotalInfo, incomeData) {
	const rows = incomeData;
	// const onlyNeededRowsInfo = _.slice(rows, 2, rows.length - 1);
	// const titles = _.slice(rows, 1, rows.length - (rows.length - 2))[0];

	const { itemsMax, xType } = chartTotalInfo;
	const isNoEmptyNames = xType === CHART_X_TYPE.name && names.length >= 2;
	const allNames = [];
	const nameIndexes = [];
	let dateFormat;
	const data = [];

	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	let startArrDates;
	let endArrDates;

	let startDay;
	let startMonth;
	let startYear;
	let endMonth;
	let endYear;
	let endDay;
	let start;
	let end;
	let arr;

	if (isNoEmptyNames && xType === CHART_X_TYPE.name) {
		// define all names
		rows.map(row => {
			allNames.push(row[0]);
			return null;
		});

		// define need indexes of items in data
		names.map(name => {
			const index = allNames.indexOf(name);
			if (index < 0) return null;

			nameIndexes.push(allNames.indexOf(name));
			return null;
		});
	}

	// Data for charts when xType 'NAME'
	if (xType === CHART_X_TYPE.name) {
		if (names.length < 2) {
			rows.map((row, index) => {
				const item = {};
				if (index < 2) {
					item.name = _.get(row, '0');
					item.value = _.get(row, '1');

					data.push(item);
				}
				return null;
			});
		} else {
			nameIndexes.map(index => {
				const item = {};
				// eslint-disable-next-line prefer-destructuring
				item.name = rows[index][0];
				// eslint-disable-next-line prefer-destructuring
				item.value = rows[index][1];
				data.push(item);
				return null;
			});
		}
	}

	// Data for charts when xType 'DATE'
	if (xType === CHART_X_TYPE.date) {
		const dateStringArray = rows[0][0].split('/');
		if (dateStringArray.length === 2) {
			dateFormat = 'monthFormat';
		} else {
			dateFormat = 'fullFormat';
		}
		switch (chartTotalInfo.xDate) {
			case CHART_DATE_TYPE.allDates:
				rows.map(row => {
					const item = {};
					item.name = _.get(row, '0');
					item.value = _.get(row, '1');

					data.push(item);
					return null;
				});
				break;
			case CHART_DATE_TYPE.betweenDates:
				startArrDates = chartTotalInfo.xDateRange.split('-')[0].split('/');
				endArrDates = chartTotalInfo.xDateRange.split('-')[1].split('/');

				switch (dateFormat) {
					case 'monthFormat':
						[startMonth, startYear] = startArrDates;
						[endMonth, endYear] = endArrDates;
						start = Number(new Date(startYear, startMonth - 1));
						end = Number(new Date(endYear, endMonth - 1));

						arr = _.filter(rows, item => {
							const dateArr = item[0].split('/');
							const [month, year] = dateArr;
							const dateNumber = new Date(year, month - 1);

							return _.inRange(dateNumber, start, end + 1);
						});

						arr.map(arrItem => {
							const item = {};
							item.name = _.get(arrItem, '0');
							item.value = _.get(arrItem, '1');

							data.push(item);
							return null;
						});

						break;
					case 'fullFormat':
						[startDay, startMonth, startYear] = startArrDates;
						[endDay, endMonth, endYear] = endArrDates;
						start = Number(new Date(startYear, startMonth - 1, startDay));
						end = Number(new Date(endYear, endMonth - 1, endDay));

						arr = _.filter(rows, item => {
							const dateArr = item[0].split('/');
							const [day, month, year] = dateArr;
							const dateNumber = Number(new Date(year, month - 1, day));
							return _.inRange(dateNumber, start, end + 1);
						});

						arr.map(arrItem => {
							const item = {};
							item.name = _.get(arrItem, '0');
							item.value = _.get(arrItem, '1');

							data.push(item);
							return null;
						});

						break;
					default:
						break;
				}
				break;
			case CHART_DATE_TYPE.lastWeek:
				if (dateFormat === 'fullFormat') {
					arr = [];
					// eslint-disable-next-line no-plusplus
					for (let i = 0; i < rows.length; i++) {
						const dateString = rows[i][0];
						const dStr = dateString.split('/')[0];
						const mStr = dateString.split('/')[1];
						const yStr = dateString.split('/')[2];
						const dayNumber = new Date(yStr, mStr - 1, dStr).getDay();

						if (i === 0) {
							if (dayNumber === 0) {
								arr.push(rows[i]);
								// eslint-disable-next-line no-continue
								continue;
							}
						}

						if (dayNumber === 0) {
							break;
						} else {
							arr.push(rows[i]);
						}
					}

					arr.map(arrItem => {
						const item = {};
						item.name = _.get(arrItem, '0');
						item.value = _.get(arrItem, '1');

						data.push(item);
						return null;
					});
				}
				break;
			case CHART_DATE_TYPE.lastMonth:
				if (dateFormat === 'fullFormat') {
					const nearestDate = rows[0][0];
					const nearestDay = nearestDate.split('/')[0];
					const nearestMonth = nearestDate.split('/')[1];
					const nearestYear = nearestDate.split('/')[2];
					const monthName = monthNames[new Date(nearestYear, nearestMonth - 1, nearestDay).getMonth()];
					const yearName = new Date(nearestYear, nearestMonth - 1, nearestDay).getYear();

					arr = _.filter(rows, item => {
						const dateArr = item[0].split('/');
						const [day, month, year] = dateArr;
						const pointMonth = monthNames[new Date(year, month - 1, day).getMonth()];
						const pointYear = new Date(year, month - 1, day).getYear();

						if (pointMonth === monthName && pointYear === yearName) {
							return item;
						}
					});

					arr.map(arrItem => {
						const item = {};
						item.name = _.get(arrItem, '0');
						item.value = _.get(arrItem, '1');

						data.push(item);
						return null;
					});
				}
				break;
			case CHART_DATE_TYPE.fiveDaysLastMonth:
				if (dateFormat === 'fullFormat') {
					const nearestDate = rows[0][0];
					const nearestDay = nearestDate.split('/')[0];
					const nearestMonth = nearestDate.split('/')[1];
					const nearestYear = nearestDate.split('/')[2];
					const monthName = monthNames[new Date(nearestYear, nearestMonth - 1, nearestDay).getMonth()];
					const yearName = new Date(nearestYear, nearestMonth - 1, nearestDay).getYear();

					arr = _.filter(rows, item => {
						const dateArr = item[0].split('/');
						const [day, month, year] = dateArr;
						const pointMonth = monthNames[new Date(year, month - 1, day).getMonth()];
						const pointYear = new Date(year, month - 1, day).getYear();

						if (pointMonth === monthName && pointYear === yearName) {
							return item;
						}
					});

					if (arr.length <= 5) {
						arr.map(arrItem => {
							const item = {};
							item.name = _.get(arrItem, '0');
							item.value = _.get(arrItem, '1');

							data.push(item);
							return null;
						});
					} else {
						arr.map((arrItem, i) => {
							const item = {};
							item.name = _.get(arrItem, '0');
							item.value = _.get(arrItem, '1');

							if (i % 5 === 0) {
								data.push(item);
							}
							return null;
						});
					}
				}
				break;
			case CHART_DATE_TYPE.monthlyAvg:
				if (dateFormat === 'fullFormat') {
					const months = [];
					const monthsData = [];
					const average = arrI => arrI.reduce((p, c) => p + c, 0) / arrI.length;
					// Create need months names array
					rows.map((item, i) => {
						const day = item[0].split('/')[0];
						const month = item[0].split('/')[1];
						const year = item[0].split('/')[2];
						const monthName = monthNames[new Date(year, month - 1, day).getMonth()];
						if (!_.includes(months, monthName) && months.length < itemsMax) {
							months.push(monthName);
						}
						return null;
					});

					// Create need months data array
					months.map(monthItemName => {
						const infoItem = {
							name: '',
							values: [],
						};
						// eslint-disable-next-line no-plusplus
						for (let i = 0; i < rows.length; i++) {
							// Index 0 - date string in rows item
							const day = rows[i][0].split('/')[0];
							const month = rows[i][0].split('/')[1];
							const year = rows[i][0].split('/')[2];
							const monthName = monthNames[new Date(year, month - 1, day).getMonth()];
							if (monthName === monthItemName) {
								infoItem.name = `${month}/${year}`;
								infoItem.values.push(rows[i][1]);
							}
						}
						monthsData.push(infoItem);
						return null;
					});

					// Create need data
					monthsData.map(monthData => {
						const item = {};
						item.name = monthData.name;
						item.value = average(monthData.values);
						data.push(item);
						return null;
					});
				}
				break;
			default:
				break;
		}
	}
	return data;
}
