// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Responsive } from 'client/components/common';
import { thousandsSeparator } from 'common/utils';

import { CHART_COLOR_TYPE, CHART_BG, CHART_FONT } from 'common/constants/chart-model';

import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	// Legend,
	// CartesianAxis,
	ResponsiveContainer,
	ReferenceLine,
	LabelList,
} from 'recharts';

import * as css from './LineChart.scss';

// const DEFAULT_STEPS_LENGTH = 8;
// const DAEFAULT_STEP_STRING = 'default';

type Props = {|
	active: number,
	names: Array<any>,
	chartData: Array<{}>,
	// chartSettings: Object,
	generalSettings: Object,
	colors: Array<string>,
	tabsInfo: Array<{}>,
|};

const NameForLine = props => {
	let str = '';
	const strArray = _.get(props, 'array', []);
	const maxLength = _.get(props, 'maxLength', 12);
	const theme = _.get(props, 'theme', {});
	const color = _.get(props, 'color', '#000');
	console.info('strArray', strArray);
	return (
		<div xmlns="http://www.w3.org/1999/xhtml">
			{strArray.map((word, index) => {
				// check if name already has current word
				if (str.includes(word)) {
					return null;
				}
				if (strArray[index + 1] === undefined) {
					if (word.length > maxLength) {
						return (
							<p
								className={classNames(
									css.multipleLines,
									theme.font === CHART_FONT.yonit ? css.yonit : '',
									css.elepsis
								)}
								style={{ color }}
								key={`line${word}`}
							>
								{`${word}`}
							</p>
						);
					}
					return (
						<p
							className={classNames(css.multipleLines, theme.font === CHART_FONT.yonit ? css.yonit : '')}
							style={{ color }}
							key={`line${word}`}
						>
							{`${word}`}
						</p>
					);
				}

				if (word.length + strArray[index + 1].length + 1 < maxLength) {
					str = str + word + strArray[index + 1];
					return (
						<p
							className={classNames(
								css.multipleLines,
								theme.font === CHART_FONT.yonit ? css.yonit : '',
								css.elepsis
							)}
							style={{ color }}
							key={`line${word}`}
						>
							{`${word} ${strArray[index + 1]}`}
						</p>
					);
				}

				if (word.length + strArray[index + 1].length + 1 > maxLength) {
					str += word;
					if (word.length > 12) {
						return (
							<p
								className={classNames(
									css.multipleLines,
									theme.font === CHART_FONT.yonit ? css.yonit : '',
									css.elepsis
								)}
								style={{ color }}
								key={`line${word}`}
							>
								{`${word}`}
							</p>
						);
					}
					return (
						<p
							className={classNames(
								css.multipleLines,
								theme.font === CHART_FONT.yonit ? css.yonit : '',
								css.elepsis
							)}
							style={{ color }}
							key={`line${word}`}
						>
							{`${word}`}
						</p>
					);
				}
				return null;
			})}
		</div>
	);
};

const CustomizedLabel = props => {
	const x = _.get(props, 'x', 0);
	// const y = _.get(props, 'y', 0);
	const index = _.get(props, 'index', 0);
	const dataLength = _.get(props, 'dataLength', 0);
	const name = _.get(props, 'name', '');
	const color = _.get(props, 'color', '');
	const theme = _.get(props, 'theme', {});
	const positions = _.get(props, 'positions', []);
	const currentItem = _.find(positions, { name });
	const positionY = _.get(currentItem, 'positionY');

	// console.info('positions', positions);
	// console.info('currentItem', positionY);
	const lastIndex = dataLength - 1;
	if (lastIndex !== index) return null;
	const strArray = name.split(' ');
	return (
		// <text
		// 	x={x}
		// 	y={`${positionY}%`}
		// 	dx={10}
		// 	dy={5}
		// 	textAnchor="right"
		// 	fill={color}
		// 	className={classNames(css.multipleLines, theme.font === CHART_FONT.yonit ? css.yonit : '')}
		// >
		// 	{name}
		// </text>
		<foreignObject x={x + 5} y={`${positionY - 1}%`} width="70" height="24">
			<NameForLine theme={theme} maxLength={12} array={strArray} color={color} />
		</foreignObject>
	);
};

const CustomizedTooltip = props => {
	const data = _.get(props, 'payload', []);
	const date = _.get(props, 'payload[0].payload.date', '');
	const theme = _.get(props, 'theme', {});
	const isPercent = _.get(props, 'isPercent', false);

	const tooltipList = _.get(props, 'payload[0].payload', []);
	const tooltipListArray = _.reduce(
		tooltipList,
		(acc, item, index: string) => {
			if (index !== 'point' && index !== 'date') {
				acc.push({
					value: item,
					name: index,
				});
			}
			return acc;
		},
		[]
	);

	const sortedDataByOrder = _.orderBy(tooltipListArray, ['value'], ['desc']);

	return (
		<div className={css.customTooltip}>
			<div className={classNames(css.tooltipTitle, theme.font === CHART_FONT.yonit ? css.yonit : '')}>{date}</div>
			<ul className={css.tooltipList}>
				{sortedDataByOrder.map(item => {
					const general = _.find(data, { name: item.name });
					return (
						<li className={css.tooltipItem} key={`${item.name}`}>
							<div
								className={classNames(
									css.infoWrapper,
									theme.font === CHART_FONT.yonit ? css.yonit : ''
								)}
							>
								<div className={css.value}>
									{thousandsSeparator(item.value)}
									{isPercent && '%'}
								</div>
								<div className={css.name}>:{item.name}</div>
							</div>
							<div className={css.colorWrapper} style={{ backgroundColor: general?.color }}></div>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

const CustomizedYaxisTick = props => {
	const x = _.get(props, 'x', 0);
	const y = _.get(props, 'y', 0);
	const value = _.get(props, 'payload.value', 0);
	const theme = _.get(props, 'theme', {});
	const isPercent = _.get(props, 'isPercent', false);
	return (
		<text
			className={classNames(css.yAxisTick, theme.font === CHART_FONT.yonit ? css.yonit : '')}
			x={x}
			y={y}
			dx={5}
			dy={4}
			textAnchor="end"
		>
			{thousandsSeparator(value)} {isPercent && '%'}
		</text>
	);
};

const CustomizedXaxisTick = props => {
	const x = _.get(props, 'x', 0);
	const y = _.get(props, 'y', 0);
	const index = _.get(props, 'index', 0);
	const value = _.get(props, 'payload.value', 0);
	const theme = _.get(props, 'theme', {});
	const lastIndex = _.get(props, 'visibleTicksCount', 0);
	if (index === 0 || index === lastIndex - 1) {
		return (
			<text
				className={classNames(css.xAxisTick, theme.font === CHART_FONT.yonit ? css.yonit : '')}
				x={x}
				y={y}
				dy={22}
				textAnchor="middle"
			>
				{value}
			</text>
		);
	}
	return null;
};

class CustomLine extends React.PureComponent<Props> {
	componentDidMount() {}

	componentDidUpdate(prevProps: Props) {}

	createTicks = (min: string | number, max: string | number, step: string | number) => {
		const currentMin = Number(min);
		const currentMax = Number(max);
		const currentStep = Number(step);
		let currentValue;
		const arr = [];

		if (currentMin < 0) {
			const negativeArray = [];
			const positiveArray = [];
			let currentValuePositive = 0;
			let currentValueNegative = 0;
			while (currentValueNegative > currentMin) {
				negativeArray.push(currentValueNegative);
				currentValueNegative -= currentStep;
			}

			if (currentValueNegative < currentMin) {
				negativeArray.push(currentValueNegative);
			}

			while (currentValuePositive < currentMax) {
				positiveArray.push(currentValuePositive);
				currentValuePositive += currentStep;
			}

			if (currentValuePositive > currentMax) {
				positiveArray.push(currentValuePositive);
			}

			const calculatedArray = [
				..._.slice(_.reverse(negativeArray), 0, negativeArray.length - 1),
				...positiveArray,
			];

			_.map(calculatedArray, item => {
				arr.push(item);
			});
		} else {
			currentValue = Number(min);
			// currentValue = Number(min);
			// currentValue = currentMin;
			// arr.push(currentMin - currentStep);
			// if (currentMin !== 0) {
			// 	arr.push(currentMin - currentStep);
			// }
			while (currentValue < currentMax) {
				arr.push(currentValue);
				currentValue += currentStep;
			}

			if (currentValue >= currentMax) {
				arr.push(currentMax);
			}
		}
		return arr;
	};

	minData = (dataMin: number) => {
		const { active, generalSettings } = this.props;
		const { yMin } = generalSettings;
		const isActiveFirst = active === 0;
		if (isActiveFirst) {
			return dataMin - (dataMin - yMin);
		}
		return Math.round(dataMin - dataMin * 0.01);
	};

	maxData = (dataMax: number) => {
		const { active, generalSettings } = this.props;
		const { yMax } = generalSettings;
		const isActiveFirst = active === 0;
		if (isActiveFirst) {
			return dataMax + (yMax - dataMax);
		}
		return Math.round(dataMax + dataMax * 0.01);
	};

	setCrosslineNamesPositions = () => {
		const { chartData, generalSettings, active } = this.props;
		const { yMax, yMin } = generalSettings.yAxisIntervals[active];

		// console.info('yMax, yMin', yMax, yMin);
		// console.info('generalSettings.yMax, generalSettings.yMin', generalSettings.yMax, generalSettings.yMin);

		const totalHeight = 400; // Total Graph Height - px
		const nameHeight = 24; // Name text Height - px
		const nameHeightInPercents = nameHeight / (totalHeight / 100); // Name Height % from Graph Total Height

		const isMobile = Responsive.isMatching(Responsive.MOBILE);
		const topPadding = isMobile ? 28 / (400 / 100) : 38 / (400 / 100); // % === 7.5% from Number Values on YAxis
		const bottomPadding = isMobile ? 40 / (400 / 100) : 40 / (400 / 100); // % === 10% from Number Values on YAxis

		// 100% - Number Values Interval on YAxis
		const neturalHeight = yMax - yMin;
		// Number Values Interval / 100% - 10 - 7.5 = 82.5% = One percent
		const percent = neturalHeight / (100 - topPadding - bottomPadding);
		const lastItem = chartData[chartData.length - 1]; // Names Data

		// Perform data to type [{ point: number, name: string }]
		const arr = _.reduce(
			lastItem,
			(acc, item, index) => {
				acc.push({
					point: item,
					name: index,
				});
				return acc;
			},
			[]
		);

		const currentArray = arr.slice(2, arr.length);
		const sortedArr = _.sortBy(currentArray, 'point', 'asc');
		const positions = [];

		_.forEach(sortedArr, item => {
			if (yMax >= item.point && item.point >= yMin) {
				const y = 100 - bottomPadding - (item.point - yMin) / percent;

				positions.push({
					point: item.point,
					positionY: y,
					name: item.name,
				});
			}
		});

		// console.info(sortedArr);

		// going from XAxis to top
		_.forEach(positions, (item, index) => {
			let itemMaxPosition;
			if (item.name.length > 12) {
				itemMaxPosition = item.positionY + nameHeightInPercents;
			} else {
				itemMaxPosition = item.positionY + nameHeightInPercents / 2;
			}
			// console.info('itemMaxPosition', itemMaxPosition);
			if (index > 0) {
				const prevItemPosition = positions[index - 1].positionY;

				// console.info('itemMaxPosition >= prevItemPosition', itemMaxPosition >= prevItemPosition);
				if (itemMaxPosition >= prevItemPosition) {
					if (item.name.length > 12) {
						// console.info('prevItemPosition', prevItemPosition);
						positions[index].positionY = prevItemPosition - nameHeightInPercents; // UP
					} else {
						positions[index].positionY = prevItemPosition - nameHeightInPercents / 2; // UP
					}
				}
			}
		});

		// console.info('positions', positions);
		return positions;
	};

	render() {
		const { chartData, names, colors, generalSettings, active, tabsInfo } = this.props;
		const bgImage = generalSettings.theme.bg !== CHART_BG.default && generalSettings.theme.bg === CHART_BG.n12;
		const bgImageMako = generalSettings.theme.bg !== CHART_BG.default && generalSettings.theme.bg === CHART_BG.mako;
		const isMultiple = chartData.length > 1;
		const isMultipleColors = generalSettings.theme.color === CHART_COLOR_TYPE.multiple;
		const { colorMain } = generalSettings.theme;
		const { enabled } = generalSettings.crossLine;
		const { theme, crosslineArray } = generalSettings;
		const tabInfo = tabsInfo[active];
		const { yMax, yMin, step } = generalSettings.yAxisIntervals[active];
		const { isPercent } = generalSettings;
		const limitNumber = 100000;
		let chartMarginsResponsive;
		if (Responsive.isMatching(Responsive.DESKTOP)) {
			chartMarginsResponsive = {
				top: 40,
				right: 80,
				left: Number(yMax) > limitNumber ? 60 : 33,
				bottom: 10,
			};
		} else {
			chartMarginsResponsive = {
				top: 30,
				right: 70,
				left: Number(yMax) > limitNumber ? 25 : 5,
				bottom: 10,
			};
		}

		// Crossline Check
		const showCrossLine = enabled;

		const ticksArr = this.createTicks(yMin, yMax, step);

		const positions = this.setCrosslineNamesPositions();
		console.info('generalSettings', generalSettings);
		return (
			<div className={classNames(css.lineChart, bgImage && css.withBg, bgImageMako && css.withBgMako)}>
				<ResponsiveContainer width="100%" height="100%">
					<LineChart width={600} height={300} data={chartData} margin={chartMarginsResponsive}>
						<XAxis
							dataKey="date"
							tick={<CustomizedXaxisTick theme={theme} />}
							tickLine={false}
							stroke="#d8d8d8"
							// axisLine={false}
						/>
						<YAxis
							// domain={[dataMin => this.minData(dataMin), dataMax => this.maxData(dataMax)]}
							domain={['dataMin', 'dataMax']}
							tick={<CustomizedYaxisTick theme={theme} tabInfo={tabInfo} isPercent={isPercent} />}
							tickLine={false}
							stroke="#d8d8d8"
							interval={0}
							// ticks={
							// 	isActiveFirst
							// 		? this.createTicks(generalSettings.yMin, generalSettings.yMax, generalSettings.step)
							// 		: false
							// }
							ticks={ticksArr}
						/>
						<CartesianGrid stroke="#d8d8d8" vertical={false} />
						<Tooltip
							isAnimationActive={false}
							content={<CustomizedTooltip theme={theme} isPercent={isPercent} />}
						/>
						{showCrossLine && (
							<ReferenceLine
								y={Number(crosslineArray[active].position)}
								label={{
									position: 'insideBottomRight',
									offset: Responsive.isMatching(Responsive.DESKTOP) ? 10 : 5,
									fontSize: Responsive.isMatching(Responsive.DESKTOP) ? 20 : 17,
									fontFamily: theme.font === CHART_FONT.yonit ? 'Yonit Bold' : '',
									fontWeight: theme.font === CHART_FONT.yonit ? 'normal' : 'bold',
									value: crosslineArray[active].title,
								}}
								stroke={crosslineArray[active].color}
								strokeWidth={Responsive.isMatching(Responsive.DESKTOP) ? 6 : 4}
							/>
						)}

						{names.map((name, index) => {
							return (
								<Line
									type="monotone"
									dataKey={name}
									stroke={isMultiple && isMultipleColors ? colors[index] : colorMain}
									strokeWidth={4}
									key={`${name}`}
									dot={{
										fill: isMultiple && isMultipleColors ? colors[index] : colorMain,
										strokeWidth: 2,
									}}
									activeDot={{
										fill: isMultiple && isMultipleColors ? colors[index] : colorMain,
										strokeWidth: 2,
										stroke: isMultiple && isMultipleColors ? colors[index] : colorMain,
									}}
								>
									{names.length > 2 && (
										<LabelList
											dataKey={name}
											position="right"
											content={
												<CustomizedLabel
													name={name}
													dataLength={chartData.length}
													color={isMultiple && isMultipleColors ? colors[index] : colorMain}
													theme={theme}
													positions={positions}
												/>
											}
										/>
									)}
								</Line>
							);
						})}
					</LineChart>
				</ResponsiveContainer>
			</div>
		);
	}
}

export default CustomLine;
