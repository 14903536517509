// @flow
import _ from 'lodash';
import { CHART_TYPE } from 'common/constants/chart-model';
import { isDDMMYYYY, isMMYYYY } from 'common/utils/regex';

type dataItem = {
	name: string,
	rows: Array<Array<string>>,
};
type dataType = Array<dataItem>;

/**
 * Parse data received from XLS file
 * @returns {[]}
 */
export const parseData = (data: dataType, itemType: string) => {
	const tabs = [];

	if (itemType === CHART_TYPE.line) {
		_.forEach(data, (dataObj, dataObjIndex) => {
			const tabValues = [];
			const tabDates = [];
			const tabNames = [];
			const tabPoints = [];
			let tabUnit = 'number';
			// slice 1st row, this is a labels
			_.forEach(dataObj.rows.slice(1), row => {
				// skip 0 and 1 index. 0 - point, 1 - date
				tabValues.push(
					...row
						.filter((rowValue, rowValueIndex) => rowValueIndex > 1)
						.map(v => {
							if (v.match('%')) {
								tabUnit = '%';
							}
							return parseFloat(v.replace(/,/g, '').replace('%', ''));
						})
				);
				tabDates.push(row[1]);
				tabPoints.push(+row[0]);
			});
			// skip 0 and 1 index. 0 - point, 1 - date
			tabNames.push(...dataObj.rows[0].slice(2));

			tabs.push({
				title: dataObj.name,
				names: tabNames,
				points: tabPoints,
				dates: tabDates,
				values: tabValues,
				unit: tabUnit,
				max: _.max(tabValues),
				min: _.min(tabValues),
			});
		});
	}

	if (itemType === CHART_TYPE.bar) {
		_.forEach(data, (dataObj, dataObjIndex) => {
			const tabValues = [];
			const tabDates = [];
			const tabNames = [];
			let tabUnit = 'number';
			let isDate = false;

			// slice 1st row, this is a labels
			_.forEach(dataObj.rows.slice(1), (row, rowIndex) => {
				// get only 1 index. 1 - values. 0 - names/dates
				const [field, value] = row;
				tabValues.push(parseFloat(value.replace(/,/g, '').replace('%', '')));
				if (value.match('%')) {
					tabUnit = '%';
				}

				if (rowIndex === 0) {
					isDate = isDDMMYYYY.test(field) || isMMYYYY.test(field);
				}

				if (isDate) {
					tabDates.push(field);
				} else {
					tabNames.push(field);
				}
			});

			tabs.push({
				title: dataObj.name,
				names: tabNames,
				dates: tabDates,
				values: tabValues,
				unit: tabUnit,
				max: _.max(tabValues),
				min: _.min(tabValues),
			});
		});
	}

	return tabs;
};

type lineData = {
	title: string,
	names: Array<string>,
	points: Array<number>,
	dates: Array<string>,
	values: Array<number>,
	unit: 'number' | '%',
	max: number,
	min: number,
};
export const transformLineDataToRows = (data: lineData) => {
	const { values, names, dates, points } = data;
	const result = ['Points', 'Dates', ...names];
	const dataRows = dates.map((date, index) => {
		const start = index === 0 ? 0 : names.length * index;
		return [points[index], date, ...values.slice(start, names.length * (index + 1))];
	});
	return [result, ...dataRows];
};

type barData = {
	title: string,
	names: Array<string>,
	dates: Array<string>,
	values: Array<number>,
	unit: 'number' | '%',
	max: number,
	min: number,
};
export const transformBarDataToRows = (data: barData) => {
	const { names, dates, values } = data;
	// flow-disable-next-line
	return (names.length ? names : dates).map((name, index) => [name, values[index]]);
};
