/* eslint-disable no-case-declarations */
import _ from 'lodash';
import { CHART_DATE_TYPE } from 'common/constants/chart-model';

export function parseLineData(names, chartTotalInfo, incomeData) {
	const rows = incomeData;
	const onlyNeededRowsInfo = _.filter(rows, o => {
		return o.length > 0;
	});

	const dataWhithoutTitles = _.slice(onlyNeededRowsInfo, 1, onlyNeededRowsInfo.length);
	const titles = _.slice(onlyNeededRowsInfo, 0, 1)[0];
	const { xDate } = chartTotalInfo;
	const data = [];
	const neededIndexes = [];

	const isEmptyNames = names.length === 0;
	const isHasNames = names.length > 0;

	let dateFormat;

	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	let startDay;
	let startMonth;
	let startYear;
	let endMonth;
	let endYear;
	let endDay;
	let start;
	let end;
	let arr;

	let monthName;
	let yearName;
	let pointMonth;
	let pointYear;

	const nearestDate = dataWhithoutTitles[0][1];
	const nearestDay = nearestDate.split('/')[0];
	const nearestMonth = nearestDate.split('/')[1];
	const nearestYear = nearestDate.split('/')[2];

	if (names.length > 0) {
		names.map(i => {
			const index = titles.indexOf(i);
			if (index < 0) return null;
			neededIndexes.push(titles.indexOf(i));
			return null;
		});
	}

	const dateStringArray = rows[1][1].split('/');
	if (dateStringArray.length === 2) {
		dateFormat = 'monthFormat';
	} else {
		dateFormat = 'fullFormat';
	}

	// console.info('DATE_FORMAT', dateFormat);

	if (isEmptyNames) {
		// console.info('EMPTY_NAMES');
		switch (xDate) {
			case CHART_DATE_TYPE.allDates:
				dataWhithoutTitles.map(i => {
					const item = {};
					const title = titles[2];

					item.point = _.get(i, '0');
					item.date = _.get(i, '1');
					item[`${title}`] = _.get(i, '2');

					data.push(item);
					return null;
				});
				break;
			case CHART_DATE_TYPE.betweenDates:
				const startArr = chartTotalInfo.xDateRange.split('-')[0].split('/');
				const endArr = chartTotalInfo.xDateRange.split('-')[1].split('/');

				switch (dateFormat) {
					case 'monthFormat':
						[startMonth, startYear] = startArr;
						[endMonth, endYear] = endArr;
						start = Number(new Date(startYear, startMonth - 1));
						end = Number(new Date(endYear, endMonth - 1));

						arr = _.filter(dataWhithoutTitles, item => {
							const dateArr = item[1].split('/');
							const [month, year] = dateArr;
							const dateNumber = new Date(year, month - 1);

							return _.inRange(dateNumber, start, end + 1);
						});

						arr.map(i => {
							const item = {};
							const title = titles[2];

							item.point = _.get(i, '0');
							item.date = _.get(i, '1');
							item[`${title}`] = _.get(i, '2');

							data.push(item);
							return null;
						});

						break;
					case 'fullFormat':
						[startDay, startMonth, startYear] = startArr;
						[endDay, endMonth, endYear] = endArr;
						start = Number(new Date(startYear, startMonth - 1, startDay));
						end = Number(new Date(endYear, endMonth - 1, endDay));

						arr = _.filter(dataWhithoutTitles, item => {
							const dateArr = item[1].split('/');
							const [day, month, year] = dateArr;
							const dateNumber = new Date(year, month - 1, day);

							return _.inRange(dateNumber, start, end + 1);
						});

						arr.map(i => {
							const item = {};
							const title = titles[2];

							item.point = _.get(i, '0');
							item.date = _.get(i, '1');
							item[`${title}`] = _.get(i, '2');

							data.push(item);
							return null;
						});

						break;
					default:
						break;
				}
				break;
			case CHART_DATE_TYPE.lastWeek:
				if (dateFormat === 'fullFormat') {
					arr = [];
					// eslint-disable-next-line no-plusplus
					for (let i = 0; i < dataWhithoutTitles.length; i++) {
						const dateString = dataWhithoutTitles[i][1];
						const dStr = dateString.split('/')[0];
						const mStr = dateString.split('/')[1];
						const yStr = dateString.split('/')[2];
						const dayNumber = new Date(yStr, mStr - 1, dStr).getDay();

						if (i === 0) {
							if (dayNumber === 0) {
								arr.push(dataWhithoutTitles[i]);
								// eslint-disable-next-line no-continue
								continue;
							}
						}

						if (dayNumber === 0) {
							break;
						} else {
							arr.push(dataWhithoutTitles[i]);
						}
					}
					arr.map(i => {
						const item = {};
						const title = titles[2];

						item.point = _.get(i, '0');
						item.date = _.get(i, '1');
						item[`${title}`] = _.get(i, '2');

						data.push(item);
						return null;
					});
				}
				break;
			case CHART_DATE_TYPE.lastMonth:
				if (dateFormat === 'fullFormat') {
					monthName = monthNames[new Date(nearestYear, nearestMonth - 1, nearestDay).getMonth()];
					yearName = new Date(nearestYear, nearestMonth - 1, nearestDay).getYear();

					arr = _.filter(dataWhithoutTitles, item => {
						const dateArr = item[1].split('/');
						const [day, month, year] = dateArr;
						pointMonth = monthNames[new Date(year, month - 1, day).getMonth()];
						pointYear = new Date(year, month - 1, day).getYear();

						if (pointMonth === monthName && pointYear === yearName) {
							return item;
						}
					});

					arr.map(i => {
						const item = {};
						const title = titles[2];

						item.point = _.get(i, '0');
						item.date = _.get(i, '1');
						item[`${title}`] = _.get(i, '2');

						data.push(item);
						return null;
					});
				}
				break;
			case CHART_DATE_TYPE.fiveDaysLastMonth:
				if (dateFormat === 'fullFormat') {
					monthName = monthNames[new Date(nearestYear, nearestMonth - 1, nearestDay).getMonth()];
					yearName = new Date(nearestYear, nearestMonth - 1, nearestDay).getYear();
					arr = _.filter(dataWhithoutTitles, item => {
						const dateArr = item[1].split('/');
						const [day, month, year] = dateArr;
						pointMonth = monthNames[new Date(year, month - 1, day).getMonth()];
						pointYear = new Date(year, month - 1, day).getYear();
						if (pointMonth === monthName && pointYear === yearName) {
							return item;
						}
					});

					if (arr.length <= 5) {
						arr.map(i => {
							const item = {};
							const title = titles[2];

							item.point = _.get(i, '0');
							item.date = _.get(i, '1');
							item[`${title}`] = _.get(i, '2');

							data.push(item);
							return null;
						});
					} else {
						arr.map((i, index) => {
							const item = {};
							const title = titles[2];

							item.point = _.get(i, '0');
							item.date = _.get(i, '1');
							item[`${title}`] = _.get(i, '2');

							if (index % 5 === 0) {
								data.push(item);
							}
							return null;
						});
					}
				}
				break;
			default:
				break;
		}
	}

	if (isHasNames) {
		console.info('HAS_NAMES');
		switch (xDate) {
			case CHART_DATE_TYPE.allDates:
				dataWhithoutTitles.map(i => {
					const item = {};

					item.point = _.get(i, '0');
					item.date = _.get(i, '1');

					names.map((name, index) => {
						const neededIndex = _.get(neededIndexes, `${index}`);
						item[`${name}`] = _.get(i, `${neededIndex}`);
						return null;
					});

					data.push(item);
					return null;
				});
				break;
			case CHART_DATE_TYPE.betweenDates:
				const startArr = chartTotalInfo.xDateRange.split('-')[0].split('/');
				const endArr = chartTotalInfo.xDateRange.split('-')[1].split('/');

				switch (dateFormat) {
					case 'monthFormat':
						[startMonth, startYear] = startArr;
						[endMonth, endYear] = endArr;
						start = Number(new Date(startYear, startMonth - 1));
						end = Number(new Date(endYear, endMonth - 1));

						arr = _.filter(dataWhithoutTitles, item => {
							const dateArr = item[1].split('/');
							const [month, year] = dateArr;
							const dateNumber = new Date(year, month - 1);

							return _.inRange(dateNumber, start, end + 1);
						});

						arr.map(i => {
							const item = {};

							item.point = _.get(i, '0');
							item.date = _.get(i, '1');

							names.map((name, index) => {
								const neededIndex = _.get(neededIndexes, `${index}`);
								item[`${name}`] = _.get(i, `${neededIndex}`);
								return null;
							});

							data.push(item);
							return null;
						});

						break;
					case 'fullFormat':
						[startDay, startMonth, startYear] = startArr;
						[endDay, endMonth, endYear] = endArr;
						start = Number(new Date(startYear, startMonth - 1, startDay));
						end = Number(new Date(endYear, endMonth - 1, endDay));

						arr = _.filter(dataWhithoutTitles, item => {
							const dateArr = item[1].split('/');
							const [day, month, year] = dateArr;
							const dateNumber = new Date(year, month - 1, day);

							return _.inRange(dateNumber, start, end + 1);
						});

						arr.map(i => {
							const item = {};

							item.point = _.get(i, '0');
							item.date = _.get(i, '1');

							names.map((name, index) => {
								const neededIndex = _.get(neededIndexes, `${index}`);
								item[`${name}`] = _.get(i, `${neededIndex}`);
								return null;
							});

							data.push(item);
							return null;
						});

						break;
					default:
						break;
				}
				break;
			case CHART_DATE_TYPE.lastWeek:
				if (dateFormat === 'fullFormat') {
					arr = [];
					// eslint-disable-next-line no-plusplus
					for (let i = 0; i < dataWhithoutTitles.length; i++) {
						const dateString = dataWhithoutTitles[i][1];
						const dStr = dateString.split('/')[0];
						const mStr = dateString.split('/')[1];
						const yStr = dateString.split('/')[2];
						const dayNumber = new Date(yStr, mStr - 1, dStr).getDay();

						if (i === 0) {
							if (dayNumber === 0) {
								arr.push(dataWhithoutTitles[i]);
								// eslint-disable-next-line no-continue
								continue;
							}
						}

						if (dayNumber === 0) {
							break;
						} else {
							arr.push(dataWhithoutTitles[i]);
						}
					}
					arr.map(i => {
						const item = {};

						item.point = _.get(i, '0');
						item.date = _.get(i, '1');

						names.map((name, index) => {
							const neededIndex = _.get(neededIndexes, `${index}`);
							item[`${name}`] = _.get(i, `${neededIndex}`);
							return null;
						});

						data.push(item);
						return null;
					});
				}
				break;
			case CHART_DATE_TYPE.lastMonth:
				if (dateFormat === 'fullFormat') {
					monthName = monthNames[new Date(nearestYear, nearestMonth - 1, nearestDay).getMonth()];
					yearName = new Date(nearestYear, nearestMonth - 1, nearestDay).getYear();

					arr = _.filter(dataWhithoutTitles, item => {
						const dateArr = item[1].split('/');
						const [day, month, year] = dateArr;
						pointMonth = monthNames[new Date(year, month - 1, day).getMonth()];
						pointYear = new Date(year, month - 1, day).getYear();

						if (pointMonth === monthName && pointYear === yearName) {
							return item;
						}
					});

					arr.map(i => {
						const item = {};

						item.point = _.get(i, '0');
						item.date = _.get(i, '1');

						names.map((name, index) => {
							const neededIndex = _.get(neededIndexes, `${index}`);
							item[`${name}`] = _.get(i, `${neededIndex}`);
							return null;
						});

						data.push(item);
						return null;
					});
				}
				break;
			case CHART_DATE_TYPE.fiveDaysLastMonth:
				if (dateFormat === 'fullFormat') {
					monthName = monthNames[new Date(nearestYear, nearestMonth - 1, nearestDay).getMonth()];
					yearName = new Date(nearestYear, nearestMonth - 1, nearestDay).getYear();

					arr = _.filter(dataWhithoutTitles, item => {
						const dateArr = item[1].split('/');
						const [day, month, year] = dateArr;
						pointMonth = monthNames[new Date(year, month - 1, day).getMonth()];
						pointYear = new Date(year, month - 1, day).getYear();

						if (pointMonth === monthName && pointYear === yearName) {
							return item;
						}
					});

					if (arr.length < 5) {
						arr.map(i => {
							const item = {};

							item.point = _.get(i, '0');
							item.date = _.get(i, '1');

							names.map((name, index) => {
								const neededIndex = _.get(neededIndexes, `${index}`);
								item[`${name}`] = _.get(i, `${neededIndex}`);
								return null;
							});

							data.push(item);
							return null;
						});
					} else {
						arr.map((i, itemIndex) => {
							const item = {};

							item.point = _.get(i, '0');
							item.date = _.get(i, '1');

							names.map((name, index) => {
								const neededIndex = _.get(neededIndexes, `${index}`);
								item[`${name}`] = _.get(i, `${neededIndex}`);
								return null;
							});

							if (itemIndex % 5 === 0) {
								data.push(item);
							}

							return null;
						});
					}
				}
				break;
			default:
				break;
		}
	}

	return data;
}
