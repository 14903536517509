// @flow
import Api from './ApiWorker';

export const authAdmin = (params: { logs: boolean }): Promise<BackEndResponse<void>> =>
	Api.send<void>({
		url: '/auth/admin',
		method: 'POST',
		body: params,
		secure: true,
	});

export const authUser = (): Promise<BackEndResponse<void>> =>
	Api.send<void>({
		url: '/auth/user',
		method: 'GET',
		secure: true,
	});

export const getCustomToken = (): Promise<BackEndResponse<string>> =>
	Api.send<string>({
		url: '/auth/get-custom-token',
		method: 'GET',
	});
