/* eslint-disable global-require */
const firebaseDev = require('../firebase.dev.json');
const firebaseProd = require('../firebase.json');

class AppConfig {
	_config = null;

	get = () => {
		let config = {};
		switch (process.env.BUILD_ENV) {
			case 'production':
				config = require('../env.prod.json');
				config.firebase = firebaseProd;
				break;

			case 'development':
				config = require('../env.dev.json');
				config.firebase = firebaseDev;
				break;

			default:
				config = require('../env.local.json');
				config.firebase = firebaseDev;
				break;
		}

		return config;
	};
}

export default new AppConfig();
