// @flow
import * as React from 'react';
import _ from 'lodash';

import { type EnhancedProps } from 'client/components/common/HOCs/with-transition';
// import configProd from 'config/production';
import * as css from './Example.scss';

import { IntroWithoutAutoheight } from '../Intro';

// const domain = configProd.domains.client;
// const appUrl = domain.hostname;

type Props = {
	...EnhancedProps,
	item?: ChartsGroup,
	getGroupItem: Function,
};
type State = {
	chartsIDs: Array<string>,
};

const COLLECTION = 'groups';

class Example extends React.PureComponent<Props, State> {
	embedCodeRef: { current: ?HTMLElement | null };
	static defaultProps = {
		item: {},
	};

	state = {
		chartsIDs: [],
	};

	constructor(props: Props) {
		super(props);

		this.embedCodeRef = React.createRef();
	}

	async componentDidMount() {
		const { getGroupItem } = this.props;
		const data = await getGroupItem({ collection: COLLECTION, id: this.props.match.params.id });

		if (data.item.items.length > 0) {
			this.setState({ chartsIDs: data.item.items });

			// data.item.items.map(i => {
			// 	const c = document.createElement('div');
			// 	c.id = `mcharts-${i}`;
			// 	c.setAttribute('style', 'max-width: 100%; width: 600px; height: 600px;');
			// 	this.embedCodeRef.current.appendChild(c);
			// 	const s = document.createElement('script');
			// 	s.src = `${appUrl}/assets/embed.js`;
			// 	s.onload = () => {
			// 		window.embedWidget(`mcharts-${i}`, `?id=${i}`);
			// 	};
			// 	this.embedCodeRef.current.appendChild(s);
			// 	return null;
			// });
		}
	}

	render() {
		const { item } = this.props;

		if (_.isEmpty(item)) return null;

		return (
			<div className={css.example}>
				{_.map(this.state.chartsIDs, id => {
					return <IntroWithoutAutoheight id={id} className={css.groupIntro} key={id} />;
				})}
			</div>
		);
	}
}
// const Example = (props: Props) => {
// 	const appRef = React.useRef(null);
// 	const divRef = React.useRef(null);
// 	const [chartsIDs, setChartsIDs] = React.useState([]);

// 	const observer = React.useCallback(() => {
// 		const height = appRef.current.offsetHeight;
// 		const parentHeight = window.innerHeight;
// 		console.info('height', height);
// 		console.info('parentHeight', parentHeight);
// 		if (height !== parentHeight && Math.abs(height - parentHeight) > 5) {
// 			console.info('message_send');
// 			window.parent.postMessage(
// 				{
// 					payload: { iframeHeight: height + 1 },
// 					source: 'mako-charts',
// 					type: 'resize',
// 				},
// 				'*'
// 			);
// 		}
// 		requestAnimationFrame(observer);
// 	}, [divRef]);

// 	React.useEffect(() => {
// 		const { getGroupItem } = props;

// 		const fetchData = async () => {
// 			const data = await getGroupItem({ collection: COLLECTION, id: props.match.params.id });
// 			if (data.item && data.item.items && data.item.items.length > 0) {
// 				setChartsIDs(data.item.items);
// 			}
// 			return data;
// 		};

// 		fetchData().catch(console.error);

// 		if (!appRef.current) {
// 			appRef.current = document.getElementById('app');
// 		}
// 		requestAnimationFrame(observer);

// 		return () => {
// 			cancelAnimationFrame(observer);
// 		};
// 	}, []);

// 	if (_.isEmpty(props.item)) return null;

// 	return (
// 		<div className={css.example} ref={divRef}>
// 			{_.map(chartsIDs, id => {
// 				return <Intro id={id} className={css.groupIntro} key={id} />;
// 			})}
// 		</div>
// 	);
// };

export default Example;
