// @flow
import produce from 'immer';

import { COLLECTIONS } from 'client/constants';
import * as actions from 'client/constants/actions';

const initialState: Groups = {
	list: [],
	total: 0,
	isBusy: false,
	item: {},
};

type Action =
	| { type: typeof actions.CREATE_GROUP_ITEM.START, payload: { collection: string } }
	| { type: typeof actions.CREATE_GROUP_ITEM.SUCCESS, payload: CreateItemResult<ChartsGroup> }
	| { type: typeof actions.CREATE_GROUP_ITEM.FAIL, payload: { collection: string } };

export default function groupsReducer(state: Groups = initialState, action: Action) {
	return produce<any>(state, (draft: Groups) => {
		switch (action.type) {
			case actions.GET_GROUPS_ITEM.START:
				if (action.payload.collection === COLLECTIONS.GROUPS) {
					draft.isBusy = true;
				}
				break;
			case actions.GET_GROUPS_ITEM.SUCCESS:
				if (action.payload.collection === COLLECTIONS.GROUPS) {
					draft.item = action.payload.item;
					draft.isBusy = false;
				}
				break;
			case actions.GET_GROUPS_ITEM.FAIL:
				if (action.payload.collection === COLLECTIONS.GROUPS) {
					draft.isBusy = false;
				}
				break;
			default:
				break;
		}
	});
}
