/* eslint-disable no-plusplus */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Responsive } from 'client/components/common';
import { thousandsSeparator } from 'common/utils';

import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	ResponsiveContainer,
	ReferenceLine,
	Cell,
	LabelList,
} from 'recharts';
import {
	CHART_BG,
	CHART_X_TYPE,
	CHART_BAR_TYPE,
	CHART_COLOR_TYPE,
	CHART_FONT,
	CHART_ORDER_BARS,
} from 'common/constants/chart-model';
import * as css from './BarChart.scss';

type Props = {|
	active: number,
	// names: Array<any>,
	chartData: Array<{}>,
	// chartSettings: Object,
	generalSettings: Object,
	colors: Array<string>,
	tabsInfo: Array<{}>,
|};

// const DEFAULT_STEPS_LENGTH = 8;
// const DAEFAULT_STEP_STRING = 'default';

const CustomizedYaxisTick = props => {
	const x = _.get(props, 'x', 0);
	const y = _.get(props, 'y', 0);
	const value = _.get(props, 'payload.value', 0);
	const theme = _.get(props, 'theme', {});
	return (
		<text
			className={classNames(css.yAxisTick, theme.font === CHART_FONT.yonit ? css.yonit : '')}
			x={x}
			y={y}
			dx={Responsive.isMatching(Responsive.DESKTOP) ? -15 : 5}
			dy={4}
			textAnchor="end"
		>
			{thousandsSeparator(value)}
		</text>
	);
};

const NameForBar = props => {
	let str = '';
	const strArray = _.get(props, 'array', []);
	const maxLength = _.get(props, 'maxLength', 8);
	const theme = _.get(props, 'theme', {});
	console.info('strArray', strArray);
	return (
		<div xmlns="http://www.w3.org/1999/xhtml">
			{strArray.map((word, index) => {
				if (str.includes(word)) {
					return null;
				}

				if (strArray[index + 1] === undefined) {
					if (word.length > maxLength) {
						return (
							<p
								className={classNames(
									css.xAxisTick,
									theme.font === CHART_FONT.yonit ? css.yonit : '',
									css.elepsis
								)}
								key={`line${word}`}
							>
								{`${word}`}
							</p>
						);
					}
					return (
						<p
							className={classNames(
								css.xAxisTick,
								theme.font === CHART_FONT.yonit ? css.yonit : '',
								css.elepsis
							)}
							key={`line${word}`}
						>
							{`${word}`}
						</p>
					);
				}

				if (word.length + strArray[index + 1].length + 1 <= maxLength) {
					str = str + word + strArray[index + 1];
					return (
						<p
							className={classNames(
								css.xAxisTick,
								theme.font === CHART_FONT.yonit ? css.yonit : '',
								css.elepsis
							)}
							key={`line${word}`}
						>
							{`${word} ${strArray[index + 1]}`}
						</p>
					);
				}

				if (word.length + strArray[index + 1].length + 1 > maxLength) {
					str += word;
					if (word.length > 12) {
						return (
							<p
								className={classNames(
									css.xAxisTick,
									theme.font === CHART_FONT.yonit ? css.yonit : '',
									css.elepsis
								)}
							>
								{`${word}`}
							</p>
						);
					}
					return (
						<p
							className={classNames(
								css.xAxisTick,
								theme.font === CHART_FONT.yonit ? css.yonit : '',
								css.elepsis
							)}
							key={`line${word}`}
						>
							{`${word}`}
						</p>
					);
				}
				return null;
			})}
		</div>
	);
};

const CustomizedXaxisTick = props => {
	const x = _.get(props, 'x', 0);
	const y = _.get(props, 'y', 0);
	const value = _.get(props, 'payload.value', 0);
	const theme = _.get(props, 'theme', {});

	const strArray = value.split(' ');

	// if (Responsive.isMatching(Responsive.DESKTOP)) {
	// 	return (
	// 		<foreignObject x={x - 60} y={y - 7} width="120" height="40">
	// 			<div xmlns="http://www.w3.org/1999/xhtml">
	// 				<p className={classNames(css.xAxisTick, theme.font === CHART_FONT.yonit ? css.yonit : '')}>
	// 					{value}
	// 				</p>
	// 			</div>
	// 		</foreignObject>
	// 	);
	// }
	// return (
	// 	<foreignObject x={x - 20} y={y - 5} width="40" height="40">
	// 		<div xmlns="http://www.w3.org/1999/xhtml">
	// 			<p className={classNames(css.xAxisTick, theme.font === CHART_FONT.yonit ? css.yonit : '')}>{value}</p>
	// 		</div>
	// 	</foreignObject>
	// );
	if (Responsive.isMatching(Responsive.DESKTOP)) {
		return (
			<foreignObject x={x - 60} y={y - 7} width="120" height="40">
				<NameForBar theme={theme} maxLength={12} array={strArray} />
			</foreignObject>
		);
	}
	return (
		<foreignObject x={x - 20} y={y - 5} width="40" height="40">
			<NameForBar theme={theme} maxLength={7} array={strArray} />
		</foreignObject>
	);
};

const CustomizedYaxisTickHorizontal = props => {
	const x = _.get(props, 'x');
	const y = _.get(props, 'y');
	const value = _.get(props, 'payload.value', 0);
	const theme = _.get(props, 'theme', {});
	const fontSizeDesktop = theme.font === CHART_FONT.yonit ? 19 : 17;
	return (
		<text
			className={classNames(css.yAxisTick, theme.font === CHART_FONT.yonit ? css.yonit : '')}
			x={x}
			y={y}
			dx={Responsive.isMatching(Responsive.DESKTOP) ? -10 : -8}
			dy={-15}
			textAnchor="end"
			fontSize={Responsive.isMatching(Responsive.DESKTOP) ? fontSizeDesktop : 16}
		>
			{value}
		</text>
	);
};

const RenderCustomizedLabel = (props: any) => {
	const { x, y, width, height, font, percent } = props;
	const [xPos, setPos] = React.useState(0);
	const nodeRef = React.useRef();
	const absElPosition = Math.abs(width);
	let elementWidth;
	let pos;
	const currentFont = font === 'yonit' ? 'Yonit Bold' : '';
	const fontSizeDesktop = font === 'yonit' ? 19 : 20;
	const yPositionDesktop = font === 'yonit' ? y + height - 4 : y + height - 3;
	const yPositionMobile = font === 'yonit' ? y + height - 5 : y + height - 4;

	React.useEffect(() => {
		elementWidth = nodeRef.current?.getBoundingClientRect().width;
		if (Number(elementWidth) > absElPosition) {
			pos = x + width - Number(elementWidth) + absElPosition;
		} else {
			pos = x + width + 15;
		}
		setPos(pos);
	}, []);

	const renderValue = () => {
		if (props.value > 1000) return `${thousandsSeparator(props.value)}`;
		return `${props.value}${percent}`;
	};

	return (
		<text
			ref={nodeRef}
			x={xPos}
			y={Responsive.isMatching(Responsive.DESKTOP) ? yPositionDesktop : yPositionMobile}
			fill="#fff"
			fontSize={Responsive.isMatching(Responsive.DESKTOP) ? fontSizeDesktop : 16}
			fontWeight="bold"
			fontFamily={currentFont}
		>
			{renderValue()}
		</text>
	);
};

class CustomBar extends React.PureComponent<Props> {
	componentDidMount() {}

	componentDidUpdate(prevProps: Props) {}

	renderBarInner = (
		xType: string,
		index: number,
		colors: Array<string>,
		mainColor: string,
		isSingleColor: boolean
	) => {
		if (xType === CHART_X_TYPE.name && !isSingleColor) {
			return <Cell fill={colors[index]} key={`${colors[index]}${index}`} />;
		}
		return <Cell fill={mainColor} key={`${mainColor}${index}`} />;
	};

	minData = (dataMin: number) => {
		if (dataMin < 0) return dataMin;
		return dataMin;
	};

	maxData = (dataMax: number) => {
		const { active, generalSettings } = this.props;
		const { yMax } = generalSettings;
		const isActiveFirst = active === 0;
		if (isActiveFirst) {
			return dataMax + (yMax - dataMax);
		}
		return Math.round(dataMax + dataMax * 0.01);
	};

	createTicks = (min: string | number, max: string | number, step: string | number) => {
		// const currentMin = Number(min) > 0 ? 0 : Number(min);
		const currentMin = Number(min);
		const currentMax = Number(max);
		const currentStep = Number(step);
		let currentValue;
		const arr = [];

		if (currentMin < 0) {
			const negativeArray = [];
			const positiveArray = [];
			let currentValuePositive = 0;
			let currentValueNegative = 0;
			while (currentValueNegative > currentMin) {
				// if (currentValueNegative <= currentMin) {
				// 	negativeArray.push(currentMin);
				// 	break;
				// }
				negativeArray.push(currentValueNegative);
				currentValueNegative -= currentStep;
			}

			// if (currentValueNegative < currentMin) {
			// 	negativeArray.push(currentValueNegative);
			// }
			negativeArray.push(currentMin);
			// negativeArray.push(currentValueNegative);
			while (currentValuePositive < currentMax) {
				// if (currentValuePositive >= currentMax) {
				// 	positiveArray.push(currentMax);
				// 	break;
				// }
				positiveArray.push(currentValuePositive);
				currentValuePositive += currentStep;
			}

			// if (currentValuePositive >= currentMax) {
			// 	positiveArray.push(currentValuePositive);
			// }

			positiveArray.push(currentMax);
			// positiveArray.push(currentValuePositive);

			const calculatedArray = [
				..._.slice(_.reverse(negativeArray), 0, negativeArray.length - 1),
				...positiveArray,
			];

			_.map(calculatedArray, item => {
				arr.push(item);
			});
		} else {
			currentValue = currentMin;
			// if (showMinMaxOnFirstTabOnly) {
			// 	if (active === 0) {
			// 		currentValue = Number(min);
			// 	} else {
			// 		currentValue = Number(yAxisIntervals[active - 1].yMin);
			// 	}
			// } else {
			// 	currentValue = Number(yMin);
			// }

			while (currentValue < currentMax) {
				// if (currentValue >= currentMax) {
				// 	arr.push(currentMax);
				// 	break;
				// }
				arr.push(currentValue);
				currentValue += currentStep;
			}
			// arr.push(currentMax);
			if (currentMax < currentValue) {
				arr.push(currentValue);
			}
			arr.push(currentMax);
		}
		return arr;
	};

	render() {
		const { chartData, generalSettings, colors, active, tabsInfo } = this.props;
		const { barType, xType, crosslineArray } = generalSettings;
		const { enabled } = generalSettings.crossLine;
		const { theme } = generalSettings;
		const bgImage = generalSettings.theme.bg !== CHART_BG.default && generalSettings.theme.bg === CHART_BG.n12;
		const bgImageMako = generalSettings.theme.bg !== CHART_BG.default && generalSettings.theme.bg === CHART_BG.mako;
		const mainColor = generalSettings.theme.colorMain ? generalSettings.theme.colorMain : colors[2];
		const isSingleColor = generalSettings.theme.color === CHART_COLOR_TYPE.single;
		const isPercent = _.get(generalSettings, 'isPercent', false);
		const percent = isPercent === true ? '%' : '';
		// const firstTabIsActive = active === 0;
		const limitNumber = 100000;
		const { yMin, yMax, step } = generalSettings.yAxisIntervals[active];

		const tabInfo = tabsInfo[active];
		let verticalBarResponsiveMargins;
		let horizontalBarResponsiveMargins;

		if (Responsive.isMatching(Responsive.DESKTOP)) {
			verticalBarResponsiveMargins = {
				top: 40,
				right: 55,
				left: 33,
				bottom: 15,
			};
			horizontalBarResponsiveMargins = {
				top: 25,
				right: 0,
				left: Number(yMax) > limitNumber ? 100 : 60,
				bottom: 25,
			};
		} else {
			verticalBarResponsiveMargins = {
				top: 40,
				right: 25,
				left: 0,
				bottom: 15,
			};
			horizontalBarResponsiveMargins = {
				top: 40,
				right: -40,
				left: Number(yMax) > limitNumber ? 40 : 20,
				bottom: 15,
			};
		}

		// Crossline Check
		const showCrossLine = enabled;

		const ticksArr = this.createTicks(yMin, yMax, step);

		if (generalSettings.barsOrder === CHART_ORDER_BARS.lowToHigh) {
			chartData.sort((a: any, b: any) => {
				return a.value - b.value;
			});
		}

		if (generalSettings.barsOrder === CHART_ORDER_BARS.highToLow) {
			chartData.sort((a: any, b: any) => {
				return b.value - a.value;
			});
		}

		if (barType === CHART_BAR_TYPE.vertical) {
			return (
				<div className={classNames(css.barChart, bgImage && css.withBg, bgImageMako && css.withBgMako)}>
					<ResponsiveContainer width="100%" height="100%">
						<BarChart width={600} height={300} data={chartData} margin={verticalBarResponsiveMargins}>
							<CartesianGrid stroke="#d8d8d8" vertical={false} />
							<XAxis
								dataKey="name"
								tickLine={false}
								stroke="#d8d8d8"
								interval={0}
								// tick={{ fill: '#000', fontSize: '18px', dy: 15 }}
								tick={<CustomizedXaxisTick theme={theme} />}
							/>
							<YAxis
								domain={[dataMin => this.minData(dataMin), dataMax => dataMax]}
								ticks={ticksArr}
								interval={0}
								stroke="#d8d8d8"
								tickLine={false}
								// tick={{ fill: '#000', fontSize: '18px', dx: -20 }}
								tick={<CustomizedYaxisTick theme={theme} tabInfo={tabInfo} />}
								// padding={{ bottom: tabInfo.min < 0 ? 20 : 0 }}
							/>
							{showCrossLine && (
								<ReferenceLine
									y={Number(crosslineArray[active].position)}
									stroke={crosslineArray[active].color}
									strokeWidth={Responsive.isMatching(Responsive.DESKTOP) ? 6 : 4}
								/>
							)}
							<Bar
								dataKey="value"
								// fill="#8884d8"
								// barSize={20}
								maxBarSize={Responsive.isMatching(Responsive.DESKTOP) ? 47 : 28}
								background={false}
							>
								{chartData.map((entry, index) =>
									this.renderBarInner(xType, index, colors, mainColor, isSingleColor)
								)}
								<LabelList
									dataKey="value"
									position="top"
									offset={2}
									formatter={value => {
										if (value > 1000) return `${thousandsSeparator(value)}${percent}`;
										return `${value}${percent}`;
									}}
									style={{
										fontSize: Responsive.isMatching(Responsive.DESKTOP) ? '18px' : '12px',
										fill: '#000',
										fontFamily: theme.font === CHART_FONT.yonit && 'Yonit Bold',
										fontWeight: theme.font === CHART_FONT.yonit ? 'normal' : 'bold',
									}}
								/>
							</Bar>
							{showCrossLine && (
								<ReferenceLine
									y={Number(crosslineArray[active].position)}
									label={{
										position: 'insideBottomRight',
										offset: Responsive.isMatching(Responsive.DESKTOP) ? 10 : 5,
										fontSize: Responsive.isMatching(Responsive.DESKTOP) ? 20 : 14,
										fontFamily: theme.font === CHART_FONT.yonit && 'Yonit Bold',
										fontWeight: theme.font === CHART_FONT.yonit ? 'normal' : 'bold',
										value: crosslineArray[active].title,
									}}
									strokeWidth={0}
								/>
							)}
						</BarChart>
					</ResponsiveContainer>
				</div>
			);
		}
		return (
			<div
				className={classNames(
					css.barChart,
					css.horizontal,
					bgImage && css.withBg,
					bgImageMako && css.withBgMako
				)}
			>
				<ResponsiveContainer width="100%" height="100%">
					<BarChart
						// width={600}
						// height={300}
						data={chartData}
						layout="vertical"
						margin={horizontalBarResponsiveMargins}
					>
						{/* <CartesianGrid stroke="red" vertical={false} horizontal={false} /> */}
						{/* <XAxis dataKey="name" />
						<YAxis /> */}
						<XAxis
							type="number"
							reversed
							axisLine={false}
							tickLine={false}
							tick={{ fill: 'transparent', fontSize: '0px' }}
						/>
						<YAxis
							type="category"
							dataKey="name"
							orientation="right"
							axisLine={false}
							tickLine={false}
							tick={<CustomizedYaxisTickHorizontal theme={theme} />}
						/>
						{/* <Tooltip cursor={{ fill: 'transparent' }} /> */}
						<Bar dataKey="value" barSize={20} maxBarSize={20} background={{ fill: '#B9B9BB' }}>
							{chartData.map((entry, index) =>
								this.renderBarInner(xType, index, colors, mainColor, isSingleColor)
							)}
							<LabelList
								dataKey="value"
								content={<RenderCustomizedLabel font={theme.font} percent={percent} />}
							/>
						</Bar>
					</BarChart>
				</ResponsiveContainer>
			</div>
		);
	}
}

export default CustomBar;
