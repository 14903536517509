// @flow
import { connect } from 'react-redux';

import withParentWindowAutoHeight from 'client/components/common/HOCs/withParentWindowAutoHeight';
import { logout } from 'client/actions';
import Intro from './Intro';

const mapState = (state: ClientStore) => ({
	user: state.user,
	settings: state.settings,
});
const mapDispatch = {
	logout,
};

export const IntroWithoutAutoheight = connect(mapState, mapDispatch)(Intro);
export default connect(mapState, mapDispatch)(withParentWindowAutoHeight(Intro));
