// @flow
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

type Params = {| in?: number, out?: number, isCustomControlled?: boolean |};
type TransitionState = 'enter' | 'entering' | 'entered' | 'exit' | 'exiting' | 'exited' | 'none';

export type EnhancedProps = {|
	in: boolean,
	transitionState: TransitionState,
	callTransitionEnd: (delay: number) => void,
|};

type State = {|
	transitionState: TransitionState,
|};

export function withTransition(params: Params) {
	return function enhancer(Component: React.AbstractComponent<any>) {
		type Props = {
			in?: boolean,
			appear?: boolean,
			enter?: boolean,
			exit?: boolean,
			unmountOnExit?: boolean,
		};

		return class EnhancedComponent extends React.PureComponent<Props, State> {
			transitionEnd: Function;
			transitionEndTimeout: TimeoutID;
			stateTimeout: TimeoutID;

			static defaultProps = {
				appear: true,
				enter: true,
				exit: true,
				unmountOnExit: true,
			};

			constructor(props: Props) {
				super(props);

				this.state = {
					transitionState: 'none',
				};
			}

			componentDidMount() {
				this.callTransitionEnd(params.in || 0);
			}

			componentDidUpdate(prevProps: Props) {
				if (!params.isCustomControlled && this.props.in !== prevProps.in) {
					const duration = this.props.in ? params.in : params.out;
					this.callTransitionEnd(duration || 0);
				}
			}

			componentWillUnmount() {
				clearTimeout(this.transitionEndTimeout);
				clearTimeout(this.stateTimeout);
			}

			callTransitionEnd = (delay: number) => {
				clearTimeout(this.transitionEndTimeout);

				this.transitionEndTimeout = setTimeout(() => {
					if (this.transitionEnd) {
						this.transitionEnd();
					}
				}, delay);
			};

			addEndListener = (node: HTMLDivElement, done: Function) => {
				this.transitionEnd = done;
			};

			onTransitionComplete = () => {
				this.transitionEnd();
			};

			onEnter = () => {
				clearTimeout(this.stateTimeout);
				this.setState({ transitionState: 'enter' });
			};

			onEntering = () => {
				this.setState({ transitionState: 'entering' });

				this.stateTimeout = setTimeout(() => {
					this.setState({ transitionState: 'entered' });
				}, params.in);
			};

			// onEntered = () => {
			//  this.setState({ transitionState: 'entered' });
			// };

			onExit = () => {
				clearTimeout(this.stateTimeout);
				this.setState({ transitionState: 'exit' });
			};

			onExiting = () => {
				this.setState({ transitionState: 'exiting' });

				this.stateTimeout = setTimeout(() => {
					this.setState({ transitionState: 'exited' });
				}, params.out);
			};

			// onExited = () => {
			//  this.setState({ transitionState: 'exited' });
			// };

			render() {
				const { appear, enter, exit, unmountOnExit } = this.props;
				const { transitionState } = this.state;

				return (
					<CSSTransition
						{...this.props}
						in={this.props.in}
						addEndListener={this.addEndListener}
						onEnter={this.onEnter}
						onEntering={this.onEntering}
						// onEntered={this.onEntered} SwitchTransiton, wtf?
						onExit={this.onExit}
						onExiting={this.onExiting}
						// onExited={this.onExited}
						appear={appear}
						enter={enter}
						exit={exit}
						unmountOnExit={unmountOnExit}
					>
						<Component
							{...this.props}
							transitionState={transitionState}
							callTransitionEnd={this.callTransitionEnd}
						/>
					</CSSTransition>
				);
			}
		};
	};
}
