import React from 'react';

export const componentDidMount = effect => {
	React.useEffect(effect, []);
};

export const componentDidUpdate = (effect, deps) => {
	React.useEffect(effect, deps);
};

export const componentWillUnmount = effect => {
	React.useEffect(() => {
		return effect;
	}, []);
};
