// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import { tracker } from 'common/utils/ga';

// import { CAN_USE_DOM } from 'common/constants';
import { detectIE } from 'common/utils/detect-ie';
// import { parseURL } from 'common/utils/parse-url';

import { ROOT_ROUTE, GROUP_EXAMPLE_ROUTE } from 'client/constants';
import { checkAuthState, login, getItem } from 'client/actions';
import Intro from 'client/components/pages/Intro';
import GroupExample from 'client/components/pages/GroupExample';

import faviconImg from 'assets/favicon.png';
import generalShareImg from 'assets/client/share.jpg';
import * as css from './App.scss';

type Props = {|
	user: User,
	settings: ClientSettings,
	history: RouterHistory,
	location: RouterLocation,
	checkAuthState: typeof checkAuthState,
	getItem: typeof getItem,
	login: typeof login,
|};

class App extends React.PureComponent<Props> {
	componentDidMount() {
		const isIE = detectIE();

		if (document && document.body) {
			document.body.setAttribute('data-browser', isIE ? 'ie' : 'not-ie');
			this.removeRootPreloader();
		}

		tracker.init('UA-142761482-69' /* this.props.settings.general.gaId */);
	}

	renderHelmet = () => {
		const { settings } = this.props;
		let title = '';
		let description = '';
		let image = '';

		title = _.get(settings.texts, 'og.title', '');
		description = _.get(settings.texts, 'og.description', '');
		image = generalShareImg;

		return (
			<Helmet
				title={title}
				link={[{ rel: 'icon', type: 'image/png', href: faviconImg }]}
				meta={[
					{
						name: 'description',
						content: description,
					},
					{ property: 'og:title', content: title },
					{ property: 'og:description', content: description },
					{ property: 'og:image', content: image },
				]}
			/>
		);
	};

	removeRootPreloader = () => {
		const preloadingScreen: ?HTMLElement = document.querySelector('.preloading-screen');

		if (preloadingScreen) {
			preloadingScreen.addEventListener('transitionend', (e: TransitionEvent) => {
				setTimeout(() => {
					if (document && document.body && e.target === preloadingScreen) {
						document.body.removeChild(preloadingScreen);
					}
				}, 1500);
			});
			preloadingScreen.style.opacity = '0';
			preloadingScreen.style.zIndex = '-1000';
		}
	};

	renderRoute = (Component: Function) => {
		return ({ match, ...props }) => {
			return <Component {...props} in={match !== null} />;
		};
	};

	render() {
		return (
			<div id="app" className={css.app}>
				{this.renderHelmet()}
				<div className={css.content}>
					<Route exact path={ROOT_ROUTE} component={this.renderRoute(Intro)} />
					<Route exact path={GROUP_EXAMPLE_ROUTE} component={GroupExample} />
				</div>
			</div>
		);
	}
}

const mapState = (state: ClientStore) => ({
	user: state.user,
	settings: state.settings,
});

const mapDispatch = {
	checkAuthState,
	login,
	getItem,
};

export default withRouter(connect(mapState, mapDispatch)(App));
