// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import {
	// CHART_DATE_TYPE,
	CHART_TYPE,
	CHART_X_TYPE,
	CHART_FONT,
} from 'common/constants/chart-model';
import { CAN_USE_DOM, appConfig } from 'common/constants';
import { parseURL } from 'common/utils/parse-url';
import { parseBarData } from 'common/utils/parse-bar-data';
import { parseLineData } from 'common/utils/parse-line-data';
import { prependUrlProtocol } from 'common/utils/prepend-url-protocol';
import { transformLineDataToRows, transformBarDataToRows } from 'common/utils/parse-chart-data';
import { events } from 'common/utils/ga';
import loader from 'assets/client/loader.gif';

// import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
import { type EnhancedProps } from 'client/components/common/HOCs/with-transition';
import CustomBar from './BarChart';
import CustomLine from './LineChart';
import * as css from './Intro.scss';
import AddPopup from './AddPopup';

// eslint-disable-next-line no-unused-vars
const MULTIPLE_COLORS = [
	'#589851',
	'#c92a1c',
	'#2a3795',
	'#6e439c',
	'#d869dd',
	'#9a5ab5',
	'#ee7a30',
	'#4b9199',
	'#db5142',
	'#a1c769',
];

const DEFAULT_COLOR = 'rgb(42, 55, 149)';

// Render result with a "dangerouslySetInnerHTML={{ __html: RESULT }}"
// eslint-disable-next-line no-unused-vars
const getDisclaimer = (disclaimerString: string, disclaimerLink?: string) => {
	const regexp = /\{(?:\.|(\\\])|[^[\]\n])*\}/;
	if (!regexp.test(disclaimerString)) {
		return disclaimerString;
	}
	const text = disclaimerString.match(regexp)[0].replace(/[{|}]/g, '');

	return disclaimerString.replace(
		regexp,
		`<a href="${prependUrlProtocol(disclaimerLink, 'https')}" target="_blank" rel="noopener noreferrer">${text}</a>`
	);
};

type Props = {|
	...EnhancedProps,
	className?: string,
	// in: boolean,
	// user: User,
	// settings: ClientSettings,
	// logout: () => void,
	// getItem: () => void,
	id?: string,
|};

type State = {
	tabsInfo: Array<any>,
	active: number,
	names: Array<any>,
	// allNames: Array<any>,
	chartData: Array<{}>,
	// chartSettings: Object,
	generalSettings: Object,
	showPopup: boolean,
};

class Intro extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;

	static defaultProps = {
		className: '',
		id: '',
	};

	state = {
		tabsInfo: [],
		active: 0,
		names: [],
		// allNames: [],
		chartData: [],
		// chartSettings: {},
		generalSettings: {},
		showPopup: false,
	};

	async componentDidMount() {
		const { onTransitionComplete } = this.props;
		this.transitionCompleteTimeout = setTimeout(onTransitionComplete, parseInt(css.transitionIn, 10));

		let ID;
		const { id, view } = parseURL(CAN_USE_DOM ? window.location : {}).searchObject;
		if (this.props.id) {
			ID = this.props.id;
		} else {
			ID = id;
		}

		let chartData;
		// let namesArr;
		let names = [];
		// 1 get config
		// const { item } = await this.props.getItem(id);
		// console.info({ item });

		// DEMO BAR CHART ID: 9087d3b50a75dbfa1830
		// DEMO LINE CHART ID: 955ecaf4e5b8e605d352

		// 2 get json
		// eslint-disable-next-line max-len
		const noCacheUrl = appConfig.get().jsonNoCacheUrl.replace('{{id}}', id);

		const cacheUrl = appConfig.get().jsonCacheUrl.replace('{{id}}', ID);

		const isPreview = view === '0';
		const url = isPreview ? noCacheUrl : cacheUrl;

		const { data, ...settings } = await fetch(url).then(r => r.json());
		let rowData = [];
		console.info({ data, settings });

		// Check if we want to show active tab from left
		const isActiveTabFromLeft = _.get(settings, 'isActiveTabFromLeft', false);

		if (settings.type === CHART_TYPE.bar) {
			rowData = data.map(tab => transformBarDataToRows(tab));

			// check xType and empty array of names
			if (settings.xType === CHART_X_TYPE.name && _.isEmpty(settings.itemsName)) {
				// eslint-disable-next-line prefer-destructuring
				names = _.slice(data[0].names, 0, 8);
			} else {
				names = settings.itemsName;
			}

			const indexforRowData = isActiveTabFromLeft ? data.length - 1 : 0;

			chartData = parseBarData(names, settings, rowData[indexforRowData]);
		}

		if (settings.type === CHART_TYPE.line) {
			rowData = data.map(tab => transformLineDataToRows(tab));

			if (_.isEmpty(settings.itemsName)) {
				// eslint-disable-next-line prefer-destructuring
				// names = [data[0].names[0]];
				names = _.slice(data[0].names, 0, 10);
			} else {
				names = settings.itemsName;
			}

			const indexforRowData = isActiveTabFromLeft ? data.length - 1 : 0;

			chartData = parseLineData(names, settings, rowData[indexforRowData]);
		}

		// console.info({ rowData });

		this.setState({
			tabsInfo: data,
			active: isActiveTabFromLeft ? data.length - 1 : 0,
			generalSettings: settings,
			// allNames: data[0].names,
			// chartSettings: data[0],
			names,
			chartData,
		});
	}

	clickTabListener = e => {
		const { tabsInfo, generalSettings, names } = this.state;
		const index = e.currentTarget.getAttribute('data-tab');

		let rowData = [];
		let chartData;

		const { title } = tabsInfo[index];
		const graphTitle = _.get(generalSettings, 'texts.title', '');

		events.clickOnTab(graphTitle, title);

		if (generalSettings.type === CHART_TYPE.line) {
			rowData = tabsInfo.map(tab => transformLineDataToRows(tab));
			chartData = parseLineData(names, generalSettings, rowData[index]);
		}

		if (generalSettings.type === CHART_TYPE.bar) {
			rowData = tabsInfo.map(tab => transformBarDataToRows(tab));
			chartData = parseBarData(names, generalSettings, rowData[index]);
		}
		this.setState({
			active: +index,
			// chartSettings: tabsInfo[+index],
			chartData,
		});
	};

	colorParser = color => {
		const reg = new RegExp(/r|g|b|a|\(|\)|[ ]/g);
		const rgba = color.replace(reg, '').split(',');

		const r = +rgba[0]; // .toString(16);
		const g = +rgba[1]; // .toString(16);
		const b = +rgba[2]; // .toString(16);
		// const a = Math.round(+rgba[3] * 255); // .toString(16);

		// if (r.length === 1) r = `0${r}`;
		// if (g.length === 1) g = `0${g}`;
		// if (b.length === 1) b = `0${b}`;
		// if (a.length === 1) a = `0${a}`;

		// if ((Number(r) < 16 || r !== 'ff') && _.includes(['c', 'f', 'd', 'e'], a[0])) {
		// 	return true;
		// }

		if (
			(Number(r) >= 0 && Number(r) <= 102) ||
			(Number(g) >= 0 && Number(g) <= 102) ||
			(Number(b) >= 0 && Number(b) <= 102) ||
			color === _.get(this.state, 'generalSettings.theme.colorMain')
		) {
			return true;
		}

		return false;
		// return `#${r}${g}${b}${a}`;
	};

	renderTabs = (tabsInfo: any) => {
		const { active, generalSettings } = this.state;
		const { theme } = generalSettings;
		if (tabsInfo.length < 2) return null;

		return (
			<div className={css.tabs}>
				{tabsInfo.map((tab, index) => {
					const title = _.get(tab, 'title', '');
					const activeColor = theme.color === 'multiple' ? DEFAULT_COLOR : theme.colorMain;
					const tabBGColor = active === index ? activeColor : '#e9e9e9';

					return (
						<div
							className={classNames(
								css.tab,
								active === index && css.active,
								theme.font === CHART_FONT.yonit ? css.yonit : css.reforma
							)}
							data-tab={index}
							key={`${title}`}
							onClick={this.clickTabListener}
							style={{
								backgroundColor: tabBGColor,
								borderColor: active === index && tabBGColor,
								color: active === index && this.colorParser(theme.colorMain) ? '#fff' : '#000',
							}}
						>
							<span>{title}</span>
						</div>
					);
				})}
			</div>
		);
	};

	addItems = incomeNames => {
		const { tabsInfo, generalSettings, active } = this.state;
		// const index = e.currentTarget.getAttribute('data-tab');
		// console.info('INDEX', index);
		let rowData = [];
		let dataForChart;

		if (generalSettings.type === CHART_TYPE.line) {
			rowData = tabsInfo.map(tab => transformLineDataToRows(tab));
			dataForChart = parseLineData(incomeNames, generalSettings, rowData[active]);
		}

		if (generalSettings.type === CHART_TYPE.bar) {
			rowData = tabsInfo.map(tab => transformBarDataToRows(tab));
			dataForChart = parseBarData(incomeNames, generalSettings, rowData[active]);
		}

		this.setState({
			names: incomeNames,
			chartData: dataForChart,
			showPopup: false,
		});
	};

	showPopup = e => {
		e.preventDefault();
		const { generalSettings } = this.state;
		const { title } = generalSettings.texts;
		const btnName = e.currentTarget.getAttribute('data-name');

		events.clickOnAddBtn(title, btnName);
		this.setState({ showPopup: true });
	};

	closePopup = () => {
		this.setState({ showPopup: false });
	};

	renderTitle = () => {
		const { generalSettings } = this.state;
		const { theme } = generalSettings;
		const { title, subtitle } = generalSettings.texts;
		const lineColor = theme.color === 'multiple' ? DEFAULT_COLOR : theme.colorMain;

		return (
			<div
				className={classNames(css.chartTitle, theme.font === CHART_FONT.yonit ? css.yonit : css.reforma)}
				style={{ borderBottomColor: lineColor }}
			>
				<h2>
					{title} <span>{subtitle}</span>
				</h2>
			</div>
		);
	};

	renderDisclaimer = () => {
		const { generalSettings } = this.state;
		const { disclaimer, disclaimerLink } = generalSettings.texts;
		return getDisclaimer(disclaimer, disclaimerLink);
	};

	renderAddBtn = () => {
		const { generalSettings } = this.state;
		const { btnAdd, theme } = generalSettings;
		const isShowBtn =
			(generalSettings.type === CHART_TYPE.line && generalSettings.btnAdd.enabled) ||
			(generalSettings.type === CHART_TYPE.bar &&
				generalSettings.xType === CHART_X_TYPE.name &&
				generalSettings.btnAdd.enabled);

		const bgColor = theme.color === 'multiple' ? DEFAULT_COLOR : theme.colorMain;
		if (isShowBtn) {
			return (
				<a
					href="#"
					className={classNames(css.addBtn, theme.font === CHART_FONT.yonit ? css.yonit : css.reforma)}
					onClick={this.showPopup}
					style={{
						backgroundColor: bgColor,
						borderColor: bgColor,
						color: this.colorParser(theme.colorMain) ? '#fff' : '#000',
					}}
					data-name={btnAdd.title}
				>
					{`+ ${btnAdd.title}`}
				</a>
			);
		}
	};

	render() {
		const { className, transitionState } = this.props;
		const { generalSettings, names, chartData, tabsInfo, showPopup, active } = this.state;
		// const btnAddName = _.get(generalSettings, 'btnAdd.title', '');
		const graphTitle = _.get(generalSettings, 'texts.title', '');
		const disclaimerLogoEnable = _.get(generalSettings, 'texts.disclaimerLogoEnabled', false);
		const disclaimerLogoFromRemoteUrl = _.get(generalSettings, 'texts.disclaimerLogo.remoteURL', '');
		const disclaimerLogoFromFile = _.get(generalSettings, 'texts.disclaimerLogo.file.url', '');
		const disclaimerLogoLink = _.get(generalSettings, 'texts.disclaimerLogoLink', '');
		const themeColor = _.get(generalSettings, 'theme.color', 'single');
		const popupActionsBtnsBg =
			themeColor === 'multiple' ? DEFAULT_COLOR : _.get(generalSettings, 'theme.colorMain', DEFAULT_COLOR);
		const popupActionsBtnsColor = this.colorParser(popupActionsBtnsBg) ? '#fff' : '#000';
		// const { theme } = generalSettings;
		const font = _.get(generalSettings, 'theme.font', '');
		const isYonitFont = font === CHART_FONT.yonit;
		const barType = _.get(generalSettings, 'barType', '');
		if (!CAN_USE_DOM) {
			return null;
		}

		if (_.isEmpty(chartData)) {
			return (
				<div className={css.intro}>
					<img src={loader} alt="loader" className={css.loader} />
				</div>
			);
		}

		return (
			<div className={classNames(css.intro, css[transitionState], className)}>
				<div className={css.topWrapper}>
					{this.renderTitle()}
					<div className={css.topWrapperI}>
						{this.renderTabs(tabsInfo)}
						{this.renderAddBtn()}
					</div>
				</div>
				{generalSettings.type === CHART_TYPE.line && (
					<CustomLine
						generalSettings={generalSettings}
						// chartSettings={chartSettings}
						names={names}
						chartData={chartData}
						active={active}
						colors={MULTIPLE_COLORS}
						tabsInfo={tabsInfo}
					/>
				)}
				{generalSettings.type === CHART_TYPE.bar && (
					<CustomBar
						generalSettings={generalSettings}
						// chartSettings={chartSettings}
						// names={names}
						chartData={chartData}
						active={active}
						colors={MULTIPLE_COLORS}
						tabsInfo={tabsInfo}
					/>
				)}
				<div className={classNames(css.disclaimer, isYonitFont && css.yonit)}>
					{disclaimerLogoEnable && (
						<a href={disclaimerLogoLink || '#'} target="_blank" rel="noopener noreferrer">
							<img
								src={disclaimerLogoFromRemoteUrl || disclaimerLogoFromFile}
								alt=""
								className={css.disclaimerLogo}
							/>
						</a>
					)}
					<p dangerouslySetInnerHTML={{ __html: this.renderDisclaimer() }}></p>
				</div>
				{showPopup && (
					<AddPopup
						allNames={tabsInfo[0].names}
						currentNames={names}
						maxItems={Number(generalSettings.itemsMax)}
						closePopup={this.closePopup}
						addItems={this.addItems}
						type={generalSettings.type}
						// btnAddName={btnAddName}
						graphTitle={graphTitle}
						popupActionsBtnsBg={popupActionsBtnsBg}
						popupActionsBtnsColor={popupActionsBtnsColor}
						isYonitFont={isYonitFont}
						barType={barType}
					/>
				)}
			</div>
		);
	}
}

export default Intro;
