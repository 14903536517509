// @flow
import { connect } from 'react-redux';

import withParentWindowAutoHeight from 'client/components/common/HOCs/withParentWindowAutoHeight';
import { getGroupItem } from 'client/actions';
import Example from 'client/components/pages/GroupExample/Example';

const mapState = (state: ClientStore) => ({
	item: state.groups.item,
});

const mapDispatch = {
	getGroupItem,
};

export default connect(mapState, mapDispatch)(withParentWindowAutoHeight(Example));
