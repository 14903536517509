// @flow
export const IS_DEVEL = process.env.NODE_ENV !== 'production';

export const MAKO_ANALYTICS_PAGE_IDS = {
	MAIN: 'HP',
	QUESTION: 'question',
	HELP: 'HelpPage',
	RESULT: 'result',
	SETTING: 'settings',
};

export const COLLECTIONS = {
	GROUPS: 'groups',
};
