// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import {
	// CHART_DATE_TYPE,
	CHART_TYPE,
	CHART_BAR_TYPE,
} from 'common/constants/chart-model';

import { events } from 'common/utils/ga';
import searchIcon from 'assets/client/icon-search.svg';
import closeIcon from 'assets/client/icon-x-close_dark-gray.svg';
import * as css from './AddPopup.scss';

type Props = {|
	allNames: Array<string>,
	currentNames: Array<string>,
	maxItems: number,
	closePopup: Function,
	addItems: Function,
	type: string,
	// btnAddName: string,
	graphTitle: string,
	popupActionsBtnsColor: string,
	popupActionsBtnsBg: string,
	isYonitFont: boolean,
	barType: string,
|};

type State = {|
	selectedNames: Array<string>,
	allNames: Array<string>,
	allNamesDefault: Array<string>,
|};

class AddPopup extends React.PureComponent<Props, State> {
	static defaultProps = {
		// className: '',
	};

	state = {
		selectedNames: [],
		allNames: [],
		allNamesDefault: [],
	};

	componentDidMount() {
		const { allNames, currentNames } = this.props;

		this.setState({
			selectedNames: [...currentNames],
			allNames: [...allNames],
			allNamesDefault: [...allNames],
		});
	}

	componentDidUpdate(prevProps: Props) {}

	renderAllItems = () => {
		const { allNames, selectedNames } = this.state;
		const { popupActionsBtnsColor, popupActionsBtnsBg, isYonitFont } = this.props;
		return (
			<ul className={classNames(css.allList, isYonitFont ? css.yonit : css.reforma)}>
				{allNames.map((item, index) => {
					const checkedItem = _.indexOf(selectedNames, item) >= 0;
					return (
						<li key={`list1${item}`}>
							<input
								type="checkbox"
								name={`list1${item}`}
								id={`list1${item}`}
								data-name={item}
								checked={checkedItem}
								onChange={this.allItemsItemChangeListener}
							/>
							<label htmlFor={`list1${item}`}>
								<span className={css.disabledCheckbox}></span>
								<span
									className={classNames(
										css.enabledCheckbox,
										popupActionsBtnsColor === '#fff' && css.colorWhite
									)}
									style={{ backgroundColor: popupActionsBtnsBg }}
								></span>
								{item}
							</label>
						</li>
					);
				})}
			</ul>
		);
	};

	renderSelectedItems = () => {
		const { selectedNames } = this.state;
		const { popupActionsBtnsColor, popupActionsBtnsBg, isYonitFont } = this.props;
		return (
			<ul className={classNames(css.allSelected, isYonitFont ? css.yonit : css.reforma)}>
				{selectedNames.map(item => {
					return (
						<li key={`list2${item}`}>
							<input
								type="checkbox"
								name={`list2${item}`}
								id={`list2${item}`}
								data-name={item}
								checked
								onChange={this.selectedItemChangeListener}
							/>
							<label htmlFor={`list2${item}`}>
								<span className={css.disabledCheckbox}></span>
								<span
									className={classNames(
										css.enabledCheckbox,
										popupActionsBtnsColor === '#fff' && css.colorWhite
									)}
									style={{ backgroundColor: popupActionsBtnsBg }}
								></span>
								{item}
							</label>
						</li>
					);
				})}
			</ul>
		);
	};

	selectedItemChangeListener = (e: SyntheticEvent<HTMLInputElement>) => {
		const name = e.currentTarget.getAttribute('data-name');

		this.setState(prevState => {
			const nextNames = [...prevState.selectedNames];
			_.remove(nextNames, n => n === name);

			return {
				selectedNames: [...nextNames],
			};
		});
	};

	allItemsItemChangeListener = (e: SyntheticEvent<HTMLInputElement>) => {
		const name = e.currentTarget.getAttribute('data-name') || '';
		const nextChecked = e.currentTarget.checked;
		const { maxItems, type, graphTitle } = this.props;
		const max = type === CHART_TYPE.bar ? 7 : 10;
		const { selectedNames } = this.state;

		if (nextChecked) {
			if (maxItems && selectedNames.length === +maxItems) {
				// eslint-disable-next-line no-alert
				alert(`You can add only ${maxItems} items`);
				return;
			}
			if (!maxItems && selectedNames.length === max) {
				// eslint-disable-next-line no-alert
				alert(`You can add only ${max} items`);
				return;
			}
			events.clickOnCityCheckbox(graphTitle, name);
			this.setState(prevState => {
				return {
					selectedNames: [...prevState.selectedNames, name],
				};
			});
		} else {
			this.setState(prevState => {
				const nextNames = [...prevState.selectedNames];
				_.remove(nextNames, n => n === name);

				return {
					selectedNames: [...nextNames],
				};
			});
		}
	};

	inputHandler = (e: SyntheticEvent<HTMLInputElement>) => {
		const { allNames, allNamesDefault } = this.state;
		let newAllNames = [];
		if (e.currentTarget.value.length >= 3) {
			allNames.map(name => {
				if (name.indexOf(e.currentTarget.value) === 0) {
					newAllNames.push(name);
				}
				this.setState({
					allNames: [...newAllNames],
				});
				return null;
			});
		} else {
			newAllNames = [...allNamesDefault];
			this.setState({
				allNames: [...newAllNames],
			});
		}
	};

	clearNames = () => {
		this.setState({
			selectedNames: [],
		});
	};

	closePopup = (e: SyntheticEvent<HTMLLinkElement>) => {
		e.preventDefault();
		const { closePopup } = this.props;
		closePopup();
	};

	applyNames = (e: SyntheticEvent<HTMLLinkElement>) => {
		e.preventDefault();
		const { addItems, closePopup } = this.props;
		const { selectedNames } = this.state;

		if (selectedNames.length === 0) {
			closePopup();
		} else {
			addItems(selectedNames);
		}
	};

	render() {
		// const { maxItems } = this.props;
		const { allNames, selectedNames } = this.state;
		const { type, popupActionsBtnsColor, popupActionsBtnsBg, isYonitFont, barType } = this.props;

		if (_.isEmpty(allNames) && _.isEmpty(selectedNames)) return null;
		let max;
		if (type === CHART_TYPE.bar && barType === CHART_BAR_TYPE.vertical) {
			if (allNames.length > 7) {
				max = 7;
			} else {
				max = allNames.length;
			}
		}

		if (type === CHART_TYPE.bar && barType === CHART_BAR_TYPE.horizontal) {
			if (allNames.length > 10) {
				max = 10;
			} else {
				max = allNames.length;
			}
		}

		if (type === CHART_TYPE.line) {
			if (allNames.length > 10) {
				max = 10;
			} else {
				max = allNames.length;
			}
		}

		return (
			<div className={css.addPopup}>
				<div className={css.popupOverlay}></div>
				<div className={css.popupInner}>
					<div className={css.wrapper}>
						<h3 className={classNames(isYonitFont ? css.yonit : css.reforma)}>
							ניתן לבחור עד {max} להשוואה:
						</h3>
						<div className={classNames(css.searchInput, isYonitFont ? css.yonit : css.reforma)}>
							<input type="text" placeholder="חיפוש…" onChange={this.inputHandler} />
							<img src={searchIcon} alt="" />
						</div>
						<div className={css.selectionItems}>{this.renderAllItems()}</div>
					</div>
					<div className={css.asside}>
						<a href="" className={css.closePopup} onClick={this.closePopup}>
							<img src={closeIcon} alt="" />
						</a>
						<div className={css.assideInfo}>
							<div className={classNames(css.title, isYonitFont && css.yonit)}>הבחירה שלך:</div>
							<div className={css.selectedItems}>{this.renderSelectedItems()}</div>
							<div
								className={classNames(css.clearResults, isYonitFont ? css.yonit : css.reforma)}
								onClick={this.clearNames}
							>
								<img src={closeIcon} alt="" /> <span>נקה בחירה</span>
							</div>
							<a
								href="#"
								style={{ backgroundColor: popupActionsBtnsBg, color: popupActionsBtnsColor }}
								className={classNames(css.addItems, isYonitFont ? css.yonit : css.reforma)}
								onClick={this.applyNames}
							>
								אישור
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AddPopup;
